import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import { Utility } from "@ignite/ignite-common";
import { Subject } from "rxjs";

@Component({
  selector: "ignite-base",
  template: "",
})
export class IgniteBaseComponent implements AfterViewInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();
  useFullScreen = false;

  ngAfterViewInit(): void {
    if (this.useFullScreen) {
      const el: HTMLElement = document.querySelector(".ignite-main-content");
      if (!Utility.IsNull(el)) {
        el.classList.add("full-screen");
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.useFullScreen) {
      const el: HTMLElement = document.querySelector(".ignite-main-content");
      if (!Utility.IsNull(el)) {
        el.classList.remove("full-screen");
      }
    }
  }

  getAllErrors(form: UntypedFormGroup | UntypedFormArray): ValidationErrors {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof UntypedFormGroup || control instanceof UntypedFormArray ? this.getAllErrors(control) : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as ValidationErrors);
    return hasError ? result : null;
  }
}
