import { HelpService } from "./../../services/help.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ignite-help",
  templateUrl: "./ignite-help.component.html",
  styleUrls: ["./ignite-help.component.scss"],
})
export class IgniteHelpComponent implements OnInit {
  helpUrl = "";
  constructor(private helpService: HelpService) {}

  ngOnInit(): void {
    this.helpUrl = this.helpService.getHelpUrl();
  }
}
