<ignite-solo-card>
  <div solo-card-title>This is the card title</div>
  <div solo-card-content>
    <div class="demo-card-content">
      This is the content. It can be anything. Solo-cards are intended for un-authenticated pages, where the menu is not shown.
    </div>
    <div solo-card-actions>
      <div class="ignite-buttons-container">
        <div class="ignite-buttons">
          <div class="ignite-button-wrapper">
            <button mat-button class="ignite-button" color="primary">I do nothing</button>
          </div>
          <div class="ignite-button-wrapper">
            <button class="ignite-button" mat-button routerLink="/dashboard">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ignite-solo-card>
