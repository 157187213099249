import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Utility } from "@ignite/ignite-common";
import { IgnitePanelMenuOption } from "../../models/ignite-panel-menu-option";

@Component({
  selector: "ignite-panel",
  templateUrl: "./ignite-panel.component.html",
  styleUrls: ["./ignite-panel.component.scss"],
})
export class IgnitePanelComponent implements OnInit {
  @Input() panelTitle: string | string[];
  @Input() collapsed = false;
  @Input() showCheckedIndicator = false;
  @Input() collapsible: boolean;
  @Input() showCancelButton: boolean;
  @Input() panelMenuOptions: IgnitePanelMenuOption[];
  @Output() cancelEvent = new EventEmitter<any>();
  @Output() headerClickEvent = new EventEmitter<any>();
  @Output() toggleEvent = new EventEmitter<boolean>();

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.panelTitle)) {
      this.panelTitle = [];
    } else if (typeof this.panelTitle === "string") {
      this.panelTitle = [this.panelTitle];
    }
  }

  cancel($event): void {
    this.cancelEvent.emit($event);
  }

  onHeaderClick($event: Event): void {
    if (($event.target as HTMLElement)?.classList.contains("link")) {
      $event.stopPropagation();
    }
    if (this.headerClickEvent.observers.length > 0) {
      this.headerClickEvent.emit($event);
    }
  }

  onToggle($event: Event): void {
    if (($event.target as HTMLElement)?.classList.contains("panel-cancel")) {
      return;
    }
    $event.stopPropagation();
    if (!this.collapsible) {
      return;
    }
    this.toggleEvent.emit(this.collapsed);
  }
}
