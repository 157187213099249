<div class="ignite-date-container">
  <mat-label *ngIf="label !== ''" class="ignite-label">{{ label }}</mat-label>
  <form [formGroup]="form">
    <div class="ignite-date-fields" id="date-of-birth">
      <mat-form-field id="day-field" [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Day</mat-label>
        <mat-select ignite-select formControlName="day" (selectionChange)="updateDate()">
          <mat-option *ngFor="let day of days" [value]="day">
            {{ day }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field id="month-field" [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Month</mat-label>
        <mat-select ignite-select formControlName="month" (selectionChange)="updateDate()">
          <mat-option *ngFor="let month of months" [value]="month.value">
            {{ month.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field id="year-field" [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Year</mat-label>
        <mat-select ignite-select formControlName="year" (selectionChange)="updateDate()">
          <mat-option *ngFor="let year of years" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mat-mdc-text-field-wrapper" id="ignite-date-error-container">
      <div class="mat-mdc-form-field-subscript-wrapper">
        <mat-error id="dob_error_required" *ngIf="form.hasError('required')">{{ label }} is required</mat-error>
        <mat-error id="dob_error_invalid" *ngIf="form.hasError('invalid')">{{ label }} is invalid</mat-error>
        <mat-error id="dob_error_min" *ngIf="form.hasError('tooEarly')" i18n>{{ label }} is too early</mat-error>
        <mat-error id="dob_error_max" *ngIf="form.hasError('inFuture')" i18n>{{ label }} cannot be in the future</mat-error>
        <mat-error id="dob_error_notlegal" *ngIf="form.hasError('notLegalAge')" i18n
          >Test Taker is 15 or under, they cannot have an account.</mat-error
        >
      </div>
    </div>
  </form>
</div>
