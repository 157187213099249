/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- Angular ControlValueAccessor defined interface */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NgControl, ValidationErrors, Validator } from "@angular/forms";
import { Utility } from "@ignite/ignite-common";
import parsePhoneNumberFromString, { CountryCode, PhoneNumber } from "libphonenumber-js/max";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { IgniteCountryCallingCode } from "./../../models/ignite-country-calling-code";

// eslint-disable-next-line no-var
declare var ResizeObserver;

@Component({
  selector: "ignite-telephone-input",
  templateUrl: "./ignite-telephone-input.component.html",
  styleUrls: ["./ignite-telephone-input.component.scss"],
})
export class IgniteTelephoneInputComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges, ControlValueAccessor, Validator {
  static controlNum = 0;

  @Input() id: string;
  @Input() required = false;
  @Input() readonly = false;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() countryCallingCodes: IgniteCountryCallingCode[] | Observable<IgniteCountryCallingCode[]>;
  @Input() defaultCountryCode: CountryCode | Observable<CountryCode>;
  _defaultCountryCode: CountryCode;

  @ViewChild("telephoneInputDiv")
  telphoneInputDiv: ElementRef;
  divResizeObserver: typeof ResizeObserver = null;
  countryCodeWidth$ = new BehaviorSubject<number>(200);

  countryCode = new UntypedFormControl("", this.validateCountryCode.bind(this));
  countryCodeId: string;
  filteredCountryCallingCodes$: Observable<IgniteCountryCallingCode[]>;
  private _countryCallingCodes: IgniteCountryCallingCode[];
  private isCallingCodeNumberRegex = /\+?\d+/;

  telephoneNumber = new UntypedFormControl("", this.validateTelephone.bind(this));
  telephoneId: string;
  private _phoneNumber: PhoneNumber;
  private originalNumber: any;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public onTouched: () => void = () => {
    // do nothing
  };
  public propagateChange = (_: any) => {
    // do nothing
  };

  constructor(@Optional() @Self() public ngControl: NgControl, @Inject(LOCALE_ID) private locale: string) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.generateIds();
    this.setupCountryCallingCodes();

    this.label = Utility.IsEmptyOrNull(this.label) ? "Telephone number" : this.label;
  }

  ngAfterViewInit(): void {
    this.divResizeObserver = new ResizeObserver((entries) => {
      this.countryCodeWidth$.next(entries[0].contentRect.width);
    });

    this.divResizeObserver.observe(this.telphoneInputDiv.nativeElement);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.divResizeObserver.unobserve(this.telphoneInputDiv.nativeElement);
  }

  private generateIds(): void {
    this.id = Utility.IsEmptyOrNull(this.id) ? `ignite_telephone_input_${IgniteTelephoneInputComponent.controlNum++}` : this.id;
    this.countryCodeId = `${this.id}_country_code`;
    this.telephoneId = `${this.id}_telephone`;
  }

  private setupCountryCallingCodes(): void {
    if (this.isObservable(this.countryCallingCodes)) {
      this.countryCallingCodes.pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (countryCallingCodes) => {
          if (!Utility.IsEmptyOrNull(countryCallingCodes)) {
            this._countryCallingCodes = countryCallingCodes;
            this.writeValue(this.ngControl.value);
            this.attachEvents();
          }
        },
      });
    } else {
      this._countryCallingCodes = this.countryCallingCodes;
      this.writeValue(this.ngControl.value);
      this.attachEvents();
    }
  }

  private attachEvents() {
    if (!Utility.IsEmptyOrNull(this.defaultCountryCode) && this.isObservable(this.defaultCountryCode)) {
      this.defaultCountryCode.pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (countryIso2Code) => {
          const igniteCountryCallingCode = this.getCountryCallingCodeByCountry(countryIso2Code);
          if (!Utility.IsEmptyOrNull(igniteCountryCallingCode) && Utility.IsEmptyOrNull(this.telephoneNumber.value)) {
            this._defaultCountryCode = igniteCountryCallingCode.iso2 as CountryCode;
            this.countryCode.setValue(igniteCountryCallingCode, { emitEvent: true });
          } else {
            this._defaultCountryCode = Utility.IsEmptyOrNull(this._defaultCountryCode)
              ? (this.locale.split("-")[1] as CountryCode)
              : this._defaultCountryCode;
          }
        },
      });
    } else {
      this._defaultCountryCode = Utility.IsEmptyOrNull(this.defaultCountryCode)
        ? (this.locale.split("-")[1] as CountryCode)
        : (this.defaultCountryCode as CountryCode);
    }

    this.filteredCountryCallingCodes$ = this.countryCode.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      startWith(""),
      map((value) => {
        return this.filter(value);
      })
    );

    this.countryCode.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value) => {
        this.onTelephoneNumberChange(value, this.telephoneNumber.value);
        this.telephoneNumber.updateValueAndValidity();
        if (!Utility.IsNull(this._phoneNumber)) {
          if (this.telephoneNumber.invalid) {
            this.telephoneNumber.setValue(this._phoneNumber.nationalNumber);
          } else {
            this.telephoneNumber.setValue(this._phoneNumber.formatNational());
          }
          this.telephoneNumber.markAsTouched();
        }
      },
    });

    this.telephoneNumber.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (telephoneNumberValue) => {
        return this.onTelephoneNumberChange(this.countryCode.value, telephoneNumberValue);
      },
    });
  }

  private isObservable(observable: any): observable is Observable<IgniteCountryCallingCode[]> | Observable<CountryCode> {
    return observable.subscribe !== undefined;
  }

  private filter(value): IgniteCountryCallingCode[] {
    if (Utility.IsEmptyOrNull(value) || value === "+") {
      return this._countryCallingCodes.slice();
    } else if (this.isIgniteCountryCallingCode(value)) {
      return [value];
    } else if (this.isCallingCodeNumber(value)) {
      const callingCode = value.startsWith("+") ? value.slice(1) : value;
      return this._countryCallingCodes
        .filter((c) => c.callingCode.indexOf(callingCode) === 0)
        .sort((a, b) => (a.callingCode > b.callingCode ? 1 : a.callingCode < b.callingCode ? -1 : 0));
    } else {
      const countryName = value.toLowerCase();
      return this._countryCallingCodes
        .filter((c) => c.name.toLowerCase().indexOf(countryName) === 0)
        .sort((a, b) => (a.callingCode > b.callingCode ? 1 : a.callingCode < b.callingCode ? -1 : 0));
    }
  }

  private isCallingCodeNumber(value: any): boolean {
    let isNumber = false;
    if (!Utility.IsEmptyOrNull(value) && typeof value === "string") {
      isNumber = this.isCallingCodeNumberRegex.test(value);
    }
    return isNumber;
  }

  showCountryCallingCode(countryCallingCode: IgniteCountryCallingCode): string {
    return countryCallingCode && countryCallingCode.callingCode ? `+${countryCallingCode.callingCode}` : "";
  }

  onTelephoneNumberChange(callingCodeValue: IgniteCountryCallingCode | string, telephoneValue: string): void {
    let countryCallingCode = callingCodeValue as IgniteCountryCallingCode;
    if (this.isCallingCodeNumber(callingCodeValue)) {
      countryCallingCode = this.getCountryCallingCodeByNumber(callingCodeValue as string);
      if (!Utility.IsEmptyOrNull(countryCallingCode)) {
        this.countryCode.setValue(countryCallingCode, { emitEvent: false });
      }
    }
    const countryCode =
      Utility.IsEmptyOrNull(countryCallingCode) || !this.isIgniteCountryCallingCode(countryCallingCode)
        ? null
        : (countryCallingCode.iso2 as CountryCode);

    this._phoneNumber = null;
    if (!Utility.IsEmptyOrNull(countryCode)) {
      this._phoneNumber = parsePhoneNumberFromString(telephoneValue, countryCode);
    }

    const change = !Utility.IsEmptyOrNull(this._phoneNumber) ? this._phoneNumber.number : telephoneValue;
    this.propagateChange(change);

    this.checkPristine();
  }

  private checkPristine(): void {
    if (!Utility.IsEmptyOrNull(this._phoneNumber) && this._phoneNumber.number === this.originalNumber) {
      this.ngControl.control.markAsPristine();
    }
    if (!Utility.IsNull(this.ngControl.control)) {
      if (this.countryCode.invalid) {
        this.ngControl.control.setErrors(this.countryCode.errors);
      } else {
        this.ngControl.control.setErrors(this.telephoneNumber.errors);
      }
    }
  }

  private isIgniteCountryCallingCode(value: any): value is IgniteCountryCallingCode {
    return value.iso2 !== undefined;
  }

  private getCountryCallingCodeByNumber(callingCode: string): IgniteCountryCallingCode {
    callingCode = callingCode.startsWith("+") ? callingCode.slice(1) : callingCode;
    return this._countryCallingCodes.find((c) => c.callingCode === callingCode);
  }

  private getCountryCallingCodeByCountry(countryIso2: string): IgniteCountryCallingCode {
    return this._countryCallingCodes.find((c) => c.iso2 === countryIso2);
  }

  validateCountryCode({ value }: AbstractControl): ValidationErrors | null {
    let error: ValidationErrors = null;
    if (!Utility.IsEmptyOrNull(value) && !this.isIgniteCountryCallingCode(value)) {
      error = { unmatchedItem: true };
    }
    return error;
  }

  validateTelephone({ value }: AbstractControl): ValidationErrors | null {
    let error: ValidationErrors = null;
    if (!Utility.IsEmptyOrNull(value)) {
      let countryCallingCode = this.countryCode.value;
      if (this.isCallingCodeNumber(countryCallingCode)) {
        countryCallingCode = this.getCountryCallingCodeByNumber(countryCallingCode);
      }
      const countryCode = Utility.IsEmptyOrNull(countryCallingCode) ? this._defaultCountryCode : (countryCallingCode.iso2 as CountryCode);
      const phoneNumber = parsePhoneNumberFromString(value, countryCode);
      if (!phoneNumber || !phoneNumber.isValid()) {
        error = { invalidPhoneNumber: true };
      }
    }
    return error;
  }

  onTelephoneKeyPress(event): void {
    switch (event.key) {
      case "Backspace":
      case "Delete":
      case "ArrowLeft":
      case "ArrowRight":
        break;
      default: {
        const pattern = /[0-9+\s]/;
        if (!pattern.test(event.key)) {
          event.preventDefault();
        }
        break;
      }
    }
  }
  onCountryCodeKeyPress(event): void {
    switch (event.key) {
      case "Backspace":
      case "Delete":
      case "ArrowLeft":
      case "ArrowRight":
        break;
      default: {
        const pattern = /[a-zA-Z0-9+\s]/;
        if (!pattern.test(event.key)) {
          event.preventDefault();
        }
        break;
      }
    }
  }

  onBlur(): void {
    this.onTouched();
    this.checkPristine();
  }

  markFormTouched() {
    this.telephoneNumber.markAsTouched();
    this.countryCode.markAsTouched();
  }

  isCountryCallingCodeEmpty(): boolean {
    return Utility.IsEmptyOrNull(this.countryCode.value);
  }

  isTelephoneNumberEmpty(): boolean {
    return Utility.IsEmptyOrNull(this.telephoneNumber.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.telephoneNumber.updateValueAndValidity({ emitEvent: true });
  }

  writeValue(val: any): void {
    if (Utility.IsEmptyOrNull(this._countryCallingCodes)) {
      return;
    }
    if (!Utility.IsEmptyOrNull(val) && typeof this.originalNumber === "undefined") {
      this.originalNumber = val;
    }
    const phoneNumber = !Utility.IsEmptyOrNull(val) ? parsePhoneNumberFromString(val) : null;
    if (!Utility.IsEmptyOrNull(phoneNumber)) {
      this._phoneNumber = phoneNumber;
      this.formatTelephoneNumber();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      val && this.telephoneNumber.setValue(val, { emitEvent: true });
    }
  }

  formatTelephoneNumber(): void {
    if (!Utility.IsEmptyOrNull(this._phoneNumber)) {
      const telephoneValue = this._phoneNumber.formatNational();
      const countryCodeValue = this.getCountryCallingCodeByCountry(this._phoneNumber.country);
      this.countryCode.setValue(countryCodeValue, { emitEvent: true });
      this.telephoneNumber.setValue(telephoneValue, { emitEvent: true });
      this.telephoneNumber.updateValueAndValidity();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.countryCode.disable();
      this.telephoneNumber.disable();
    } else {
      this.countryCode.enable();
      this.telephoneNumber.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    this.validateCountryCode(this.countryCode);
    if (this.countryCode.invalid) {
      return this.countryCode.errors;
    }
    this.validateTelephone(this.telephoneNumber);
    return this.telephoneNumber.valid ? null : this.telephoneNumber.errors;
  }
}
