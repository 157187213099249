/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { QueryParams, PageResponse, DateUtility } from "@ignite/ignite-common";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  items = [
    {
      id: "5ec29166303bb425ec3e87ea",
      main: { name: "Sydney Location" },
      date: new Date(),
      streetAddress: "Level 1, Block Court, 288-290 Collins Street",
      stateProvince: ["01"],
      city: "Sydney",
      postalCode: "12345",
      country: "021",
      phone: "+12 324 567 8910",
    },
    {
      id: "5ed55ffa4cc05c57346fc975",
      main: { name: "Melbourne Location 1" },
      date: new Date(),
      streetAddress: "Location 1 address",
      stateProvince: ["02"],
      city: "Melbourne",
      postalCode: "655454",
      country: "021",
      phone: "+12 324 567 8911",
    },
    {
      id: "5ee0e18d67d4d9305c60af18",
      main: { name: "Melbourne Location 2" },
      date: new Date(),
      streetAddress: "Location 2 address",
      stateProvince: ["02"],
      city: "Melbourne",
      postalCode: "56878",
      country: "021",
      phone: "+12 324 567 8912",
    },
    {
      id: "5ee0e1ff67d4d9305c60af19",
      main: { name: "Perth" },
      date: new Date(),
      streetAddress: "10022 Main Street, unit 345",
      stateProvince: ["03"],
      city: "Perth",
      postalCode: "456554",
      country: "021",
      phone: "+12 324 567 8913",
    },
    {
      id: "5ec29166303bb425ec3e87ea",
      main: { name: "Sydney Location 3" },
      date: new Date(),
      streetAddress: "Level 1, Block Court, 288-290 Collins Street",
      stateProvince: ["01"],
      city: "Sydney",
      postalCode: "12345",
      country: "021",
      phone: "+12 324 567 8910",
    },
    {
      id: "5ed55ffa4cc05c57346fc975",
      main: { name: "Melbourne Location 5" },
      date: new Date(),
      streetAddress: "Location 1 address",
      stateProvince: ["02"],
      city: "Melbourne",
      postalCode: "655454",
      country: "021",
      phone: "+12 324 567 8911",
    },
    {
      id: "5ee0e18d67d4d9305c60af18",
      main: { name: "Melbourne Location 6" },
      date: new Date(),
      streetAddress: "Location 2 address",
      stateProvince: ["02"],
      city: "Melbourne",
      postalCode: "56878",
      country: "021",
      phone: "+12 324 567 8912",
    },
    {
      id: "5ee0e1ff67d4d9305c60af19",
      main: { name: "Canada" },
      date: new Date(),
      streetAddress: "10022 Main Street, unit 345",
      stateProvince: ["10"],
      city: "Northern city",
      postalCode: "N3T2T2",
      country: "099",
      phone: "+1(333)123-4567",
    },
    {
      id: "5ec29166303bb425ec3e87ea",
      main: { name: "Sydney Location 2" },
      date: new Date(),
      streetAddress: "Level 1, Block Court, 288-290 Collins Street",
      stateProvince: ["01"],
      city: "Sydney",
      postalCode: "12345",
      country: "021",
      phone: "+12 324 567 8910",
    },
    {
      id: "5ed55ffa4cc05c57346fc975",
      main: { name: "Melbourne Location 3" },
      date: new Date(),
      streetAddress: "Location 1 address",
      stateProvince: ["02"],
      city: "Melbourne",
      postalCode: "655454",
      country: "021",
      phone: "+12 324 567 8911",
    },
    {
      id: "5ee0e18d67d4d9305c60af18",
      main: { name: "Melbourne Location 4" },
      date: new Date(),
      streetAddress: "Location 2 address",
      stateProvince: ["02"],
      city: "Melbourne",
      postalCode: "56878",
      country: "021",
      phone: "+12 324 567 8912",
    },
    {
      id: "5ee0e1ff67d4d9305c60af19",
      main: { name: "Perth 2" },
      date: new Date(),
      streetAddress: "10022 Main Street, unit 345",
      stateProvince: ["03"],
      city: "Perth",
      postalCode: "456554",
      country: "021",
      phone: "+12 324 567 8913",
    },
  ];

  getLocations(query: QueryParams): Observable<PageResponse<any>> {
    const sorted =
      query.sort === "main.name"
        ? this.items.slice().sort((l1, l2) => (l1.main.name.toLowerCase() > l2.main.name.toLowerCase() ? 1 : -1))
        : this.items.slice().sort((l1, l2) => (l1.main.name.toLowerCase() < l2.main.name.toLowerCase() ? 1 : -1));

    const pr = new PageResponse<any>();

    let startIdx = (query.page - 1) * query.pageSize;
    if (startIdx > this.items.length) {
      query.page = 1;
      startIdx = 0;
    }

    let endIdx = query.page * query.pageSize;
    if (endIdx < this.items.length) {
      pr.hasNextPage = true;
    } else {
      endIdx = this.items.length;
    }
    pr.items = sorted.slice(startIdx, endIdx);
    pr.totalCount = pr.items.length;

    return of(pr);
  }
}
