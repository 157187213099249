<div class="ignite-footer-container">
  <div id="text_footer" class="ignite-footer">
    <div>
      <a href="https://pearsonpte.com/privacy-policy/" rel="noopener" target="_blank" i18n>Privacy policy</a> |
      <span *ngIf="showCookiesLink" ><a id="ot-sdk-btn" class="ot-sdk-show-settings" i18n>Cookies</a> | </span>
      <a href="https://pearsonpte.com/contact-us/" rel="noopener" target="_blank" i18n>Contact us</a>
    </div>
    <div i18n>Copyright © 1996-{{ year }} Pearson. All rights reserved.</div>
  </div>
</div>
