import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService, Utility } from "@ignite/ignite-common";
import { IgniteProfileMenuOption } from "../../models/ignite-profile-menu-option";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-profile-menu",
  templateUrl: "./ignite-profile-menu.component.html",
  styleUrls: ["./ignite-profile-menu.component.scss"],
})
export class IgniteProfileMenuComponent implements OnInit {
  @Input() firstName = "";
  @Input() lastName = "";
  @Input() menuOptions: IgniteProfileMenuOption[];
  isOpened = false;

  constructor(private router: Router, private navService: NavigationService) {}

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.menuOptions)) {
      this.menuOptions = [];
    }
  }

  toggleMenu($event: Event): void {
    this.isOpened = !this.isOpened;
    $event.stopPropagation();
  }

  getUserName(): string {
    if (Utility.IsEmptyOrNull(this.firstName) && Utility.IsEmptyOrNull(this.lastName)) {
      return "";
    }

    return (this.firstName.trim() + " " + this.lastName.trim()).trim();
  }

  getUserInitials(): string {
    const f = this.firstName.trim() === "" ? "" : this.firstName.trim().substring(0, 1).toUpperCase();
    const l = this.lastName.trim() === "" ? "" : this.lastName.trim().substring(0, 1).toUpperCase();
    return f + l;
  }

  onOptionClick($event: Event, option: IgniteProfileMenuOption): void {
    const currentRoute = this.router.routerState.snapshot.url.substring(1);
    if (!Utility.IsEmptyOrNull(option.route) && option.route !== currentRoute) {
      if (!Utility.IsNull(this.navService) && !Utility.IsNull(this.navService.canDeactivateComponent)) {
        this.navService.canDeactivateComponent.canDeactivate().subscribe({
          next: (proceed: boolean) => {
            if (proceed) {
              void this.router.navigate([option.route]);
            }
          },
        });
      } else {
        void this.router.navigate([option.route]);
      }
    } else {
      $event.stopPropagation();
    }
  }

  @HostListener("window:click")
  onWindowClick(): void {
    this.isOpened = false;
  }
}
