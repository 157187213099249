import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, UrlSegment } from "@angular/router";
import { IgniteProfileMenuOption } from "@ignite/ui-common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Spark";
  showMenu$: Observable<boolean>;
  currentUserFirstName = "John";
  currentUserLastName = "Doe";
  displayId = "FAF000000039";
  profileOptions = [
    new IgniteProfileMenuOption(
      `<div class="profile-user-info"><div>${this.currentUserFirstName} ${this.currentUserLastName}</div><div>PTE ID: ${this.displayId}</div></div>`
    ),
    new IgniteProfileMenuOption("Account", "fa-address-card", "account"),
    new IgniteProfileMenuOption("Log out", "fa-sign-out", "logout"),
  ];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    void this.router.navigate(["dashboard"]);

    this.showMenu$ = this.route.url.pipe(
      map((seg: UrlSegment[]) => {
        return seg.find((s: UrlSegment) => s.path.toLowerCase().includes("solo-card")) !== null;
      })
    );
  }
}
