import { Directive, ElementRef, AfterViewInit } from "@angular/core";
import { Utility } from "@ignite/ignite-common";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[ignite-dashboard-card]",
})
export class IgniteDashboardCardDirective implements AfterViewInit {
  constructor(private el: ElementRef<Element>) {}

  ngAfterViewInit(): void {
    const $el = this.el.nativeElement;
    $el.classList.add("ignite-dashboard-card");
    const isImportant: string = $el.getAttribute("important");
    if (!Utility.IsEmptyOrNull(isImportant) && isImportant === "true") {
      $el.classList.add("highlighted");
    }
  }
}
