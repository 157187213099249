/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- ControlValueAccessor interface*/
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { Utility } from "@ignite/ignite-common";
import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { IgniteSelectOption } from "../../models/ignite-select-option";
import { RequireMatchValidator } from "../../validators/require-match.validator";

@Component({
  selector: "ignite-auto-complete",
  templateUrl: "./ignite-auto-complete.component.html",
  styleUrls: ["./ignite-auto-complete.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IgniteAutoCompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IgniteAutoCompleteComponent),
      multi: true,
    },
  ],
})
export class IgniteAutoCompleteComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges, Validator {
  static nextId = 0;
  unsubscribe$: Subject<void> = new Subject<void>();

  @Input() id: string;
  @Input() label: string;
  @Input() selectOptions: IgniteSelectOption[] | Observable<IgniteSelectOption[]>;
  @Input() required = false;
  @Input() readonly = false;
  @Input() placeholder: string;
  @Input() frmCtrl: UntypedFormControl;

  filteredOptions$: Observable<IgniteSelectOption[]>;
  _selectOptions: IgniteSelectOption[] = [];

  public onTouched: () => void = () => {
    // do nothing
  };

  ngOnInit(): void {
    this.filteredOptions$ = this.frmCtrl.valueChanges.pipe(
      startWith(""),
      map((item) => (typeof item === "object" ? (item as IgniteSelectOption)?.text : (item as string))),
      map((value: string) => {
        return !Utility.IsEmptyOrNull(value) ? this.filter(value) : this._selectOptions.slice();
      })
    );

    this.frmCtrl.valueChanges.subscribe((v) => {
      if (!Utility.IsEmptyOrNull(v)) {
        if (typeof v === "string") {
          v = v.trim().toLowerCase();
          const option = this._selectOptions.find((o) => o.text.toLowerCase() === v);
          this.writeValue(option);
        }
      }
    });
  }

  private setupSelectOptions() {
    if (this.isObservable(this.selectOptions)) {
      this.selectOptions.pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (selectOptions) => {
          this.initializeValidator(selectOptions);
        },
      });
    } else {
      this.initializeValidator(this.selectOptions);
    }
  }

  private initializeValidator(options: IgniteSelectOption[]) {
    this._selectOptions = options;
    const validator = this.required
      ? [RequireMatchValidator(this._selectOptions).bind(this), Validators.required]
      : [RequireMatchValidator(this._selectOptions).bind(this)];
    this.frmCtrl.setValidators(validator);
    this.frmCtrl.updateValueAndValidity();
  }

  private isObservable(list: any): list is Observable<IgniteSelectOption[]> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return list.subscribe !== undefined;
  }

  private filter(value): IgniteSelectOption[] {
    const filterValue = (value as string)?.toLowerCase();
    return this._selectOptions.filter((option) => option.text.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (changes.selectOptions) {
      this.setupSelectOptions();
    }
    this.frmCtrl.updateValueAndValidity({ emitEvent: true });
  }

  isEmpty(): boolean {
    return Utility.IsEmptyOrNull(this.frmCtrl.value);
  }

  writeValue(val: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    val && this.frmCtrl.setValue(val, { emitEvent: true });
  }

  showValue(item: IgniteSelectOption): string {
    return item && item.text ? item.text : "";
  }

  registerOnChange(fn: any): void {
    this.frmCtrl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.frmCtrl.disable() : this.frmCtrl.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.frmCtrl.valid ? null : this.frmCtrl.errors;
  }
}
