import { Component, Input, OnInit } from "@angular/core";
import { Utility } from "@ignite/ignite-common";

@Component({
  selector: "ignite-card",
  templateUrl: "./ignite-card.component.html",
  styleUrls: ["./ignite-card.component.scss"],
})
export class IgniteCardComponent implements OnInit {
  static nextId = 0;
  @Input() id: string;
  @Input() type = "simple";
  @Input() customClassName = "";

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.id)) {
      this.id = `ignite-card-${IgniteCardComponent.nextId++}`;
    }
  }
}
