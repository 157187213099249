/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { IgniteDataSource, SortCriteria, Utility } from "@ignite/ignite-common";
import { throwError } from "rxjs";
import { UIControlType } from "../../models/ignite-control-type";
import { IgniteGridColumnDef } from "../../models/ignite-grid-column-def";
import { IgniteGridFieldDef } from "../../models/ignite-grid-field-def";
import { IgniteGridUpdateResult } from "../../models/ignite-grid-update-result";
import { IgniteDateAdapter } from "../../services/date.adapter";
import { IgniteBaseComponent } from "../ignite-base/ignite-base.component";
import { IgniteFormComponent } from "../ignite-form/ignite-form.component";

@Component({
  selector: "ignite-panel-grid",
  templateUrl: "./ignite-panel-grid.component.html",
  styleUrls: ["./ignite-panel-grid.component.scss"],
})
export class IgnitePanelGridComponent extends IgniteBaseComponent implements OnInit, OnChanges {
  static nextId = 0;
  @Input() id: string;
  @Input() name: string;
  @Input() panelTitle: string;
  @Input() dataSource: IgniteDataSource<any>;
  @Input() columnsDef: IgniteGridColumnDef[];
  @Input() fieldsDef: IgniteGridFieldDef[];
  @Input() addOnStart: boolean;
  @Input() useCustomForm: boolean;
  @Input() expandable = true;
  @Input() accordionForm: ElementRef;
  @Input() showHeaderWhenItemSelected: boolean;
  @Input() readOnly: boolean;
  @Input() addOnly: boolean;
  @Input() accordion = false;
  @Output() selectEvent = new EventEmitter<any>();
  @Output() addEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() sortEvent = new EventEmitter<SortCriteria>();
  @ViewChild("panelContent") panelContent: ElementRef;
  @ViewChild(IgniteFormComponent) gridForm: IgniteFormComponent;
  selectedRow = null;
  isAddMode = false;
  uiControlType = UIControlType;
  gridRows: any[];
  isSaving = false;
  private isInitialized = false;

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.id)) {
      this.id = `ignite-panel-grid-${IgnitePanelGridComponent.nextId++}`;
    }
  }

  ngOnChanges(c: SimpleChanges): void {
    if (!this.isInitialized && this.columnsDef && this.fieldsDef) {
      this.name = Utility.IsEmptyOrNull(this.name) ? "" : this.name;
      this.setupGrid();

      if (this.addOnStart) {
        this.addOnStart = false;
        setTimeout(() => this.select(null), 100);
      }

      this.isInitialized = true;
    }
  }

  private setupGrid(): void {
    this.fieldsDef.forEach((field) => {
      field.flatName = Utility.IsEmptyOrNull(field.flatName) ? field.name.replace(".", "-") : field.flatName;
    });

    this.dataSource.connect(null).subscribe({
      next: (items: any[]) => {
        if (!Utility.IsNull(this.accordionForm)) {
          this.accordionForm.nativeElement.remove();
        }

        const cloned = [];
        items.forEach((i) => {
          const newObj = Object.assign({}, i);
          newObj.selected = false;
          cloned.push(newObj);
        });
        this.gridRows = this.flattenList(cloned);
        this.resizeWindow();
      },
    });

    this.dataSource.connectUpdates().subscribe({
      next: (result: IgniteGridUpdateResult) => {
        if (!Utility.IsNull(result)) {
          if (result.success) {
            this.cleanUp();
          } else {
            this.isSaving = false;
            throwError(() => result.error);
          }
        }
      },
    });
  }

  private resizeWindow(): void {
    // work-around to properly align the ink-bar of the tabs control
    setTimeout(() => window.dispatchEvent(new Event("resize")), 200);
  }

  select(row: any, ev = null): void {
    if (this.isAddMode) {
      return;
    }

    if (this.expandable && (!this.useCustomForm || this.accordionForm)) {
      if (row === null) {
        row = {};
        this.isAddMode = true;
      }

      if (!Utility.IsNull(this.selectedRow)) {
        this.selectedRow.selected = false;
      }
      row.selected = true;
      this.selectedRow = row;
      if (this.accordion) {
        const theForm = this.accordionForm ?? this.panelContent;
        const panel$ = theForm.nativeElement;
        panel$.classList.remove("active");
        const targetEl = !Utility.IsNull(ev)
          ? ev.target.closest(".grid-panel-row")
          : theForm.nativeElement.closest(".grid-panel").firstChild;
        targetEl.insertAdjacentElement("afterEnd", panel$);
        setTimeout(() => {
          panel$.classList.add("active");
          if (!Utility.IsNull(targetEl.scrollIntoViewIfNeeded)) {
            targetEl.scrollIntoViewIfNeeded({ behavior: "smooth", block: "start" });
          }
        }, 0);
      }
      this.resizeWindow();
    }
    this.selectEvent.emit(row);
  }

  getFieldValue(row: any, field: string | string[], forHeader = false, mapFunction: (val: any) => string = null): string {
    if (row === null) {
      return "";
    }

    const getVal = (fname: string) => {
      fname = fname.replace(".", "-");
      let val = !row.selected ? row[fname] : Utility.IsNull(this.gridForm) ? row[fname] : this.gridForm.form.get(fname).value;
      if (forHeader && !Utility.IsEmptyOrNull(val)) {
        const def = this.fieldsDef.find((f) => f.flatName === fname);
        switch (def.controlType) {
          case this.uiControlType.MultiSelect:
            if (def.selectOptions !== null) {
              let s = "";
              val.forEach((v: string) => {
                const opt = def.selectOptions.find((o) => o.value === v);
                if (!Utility.IsNull(opt)) {
                  s += (s !== "" ? ", " : "") + opt.value;
                }
              });
              val = s;
            }
            break;
          case this.uiControlType.InputDate:
            val = IgniteDateAdapter.formatter.format(val, "mmm dd, yyyy");
            break;
          case this.uiControlType.Autocomplete:
          case this.uiControlType.DropdownList:
            if (def.selectOptions !== null) {
              const opt = def.selectOptions.find((o) => o.value === val);
              if (!Utility.IsNull(opt)) {
                val = opt.value;
              }
            }
            break;
        }
      }

      return val;
    };

    if (typeof field === "string") {
      const v = getVal(field);
      const s = Utility.IsEmptyOrNull(v) ? "" : v;
      return !Utility.IsNull(mapFunction) ? mapFunction(s) : s;
    } else {
      const s = [];
      for (const f of field) {
        const v = getVal(f);
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        s.push(Utility.IsEmptyOrNull(v) ? "" : v);
      }
      return !Utility.IsNull(mapFunction) ? mapFunction(s) : s.join(" ");
    }
  }

  cancel($event) {
    if (this.gridForm) {
      this.gridForm.cancel($event);
    }
    if (this.accordionForm) {
      this.cleanUp();
    }
  }

  addRow(row: any): void {
    this.addEvent.emit(row);
  }

  editRow(row: any): void {
    this.editEvent.emit(row);
  }

  onSort(col: IgniteGridColumnDef): void {
    if (col.sortDirection === "desc") {
      col.sortDirection = "asc";
    } else {
      col.sortDirection = "desc";
    }

    const sortCriteria = new SortCriteria(this.id);
    sortCriteria.sortColumns.push({
      columnName: col.header,
      field: !Utility.IsEmptyOrNull(col.sortField) ? col.sortField : col.field,
      direction: col.sortDirection,
    });
    this.columnsDef.forEach((c: IgniteGridColumnDef) => {
      if (c.sortable) {
        if (c.header !== col.header) {
          c.isPrimarySort = false;
          sortCriteria.sortColumns.push({ columnName: c.header, field: c.field, direction: c.sortDirection });
        } else {
          c.isPrimarySort = true;
        }
      }
    });
    this.sortEvent.emit(sortCriteria);
  }

  isGridEmpty(): boolean {
    return Utility.IsEmptyOrNull(this.gridRows);
  }

  cleanUp(): void {
    this.selectEvent.emit(null);
    setTimeout(() => {
      this.isSaving = false;
      this.isAddMode = false;
      if (this.selectedRow) {
        this.selectedRow.selected = false;
        this.selectedRow = null;
      }
      this.resizeWindow();
    }, 100);
  }

  private flattenList(rows: any[]): any[] {
    rows.forEach((r) => {
      this.fieldsDef.forEach((f) => {
        const parts = f.name.split(".");
        let v = r;
        parts.forEach((p) => {
          v = v[p];
        });
        r[f.flatName] = v;
      });
    });
    return rows;
  }
}
