<div [id]="id" class="ignite-snackbar">
  <div class="ignite-snackbar-message">
    <div class="ignite-snackbar-desktop">
      <i *ngIf="snackbarType === igniteSnackbarType.Success" class="fal fa-check-circle success"></i>
      <i *ngIf="snackbarType === igniteSnackbarType.Alert" class="fal fa-exclamation-circle alert"></i>
      <i *ngIf="snackbarType === igniteSnackbarType.Info" class="fal fa-info-circle"></i>
    </div>
    <div class="message-text">{{ message }}</div>
  </div>
  <div class="ignite-snackbar-action">
    <button *ngIf="action !== ''" type="button" mat-button (click)="onAction($event)">
      <span class="action-text"> {{ action }} </span>
    </button>
    <button type="button" mat-button (click)="dismiss($event)">
      <span class="action-text"> DISMISS </span>
    </button>
  </div>
</div>
