<div [id]="dialogId" class="dialog-confirm">
  <div class="dialog-close-icon" (click)="dialogRef.close(false)">
    <i class="fal fa-times-circle"></i>
  </div>
  <ignite-card>
    <div card-title i18n>{{ title }}</div>
    <div card-content i18n>{{ message }}</div>
  </ignite-card>

  <div class="ignite-dialog-buttons-container">
    <div class="ignite-buttons">
      <div class="ignite-button-wrapper">
        <button id="button_ok" mat-button class="ignite-button" color="primary" (click)="dialogRef.close(true)">{{ okButtonLabel }}</button>
      </div>
      <a class="ignite-link" [class.hidden]="cancelButtonLabel === ''" mat-flat-button (click)="dialogRef.close(false)">{{
        cancelButtonLabel
      }}</a>
    </div>
  </div>

  <hr *ngIf="cancelButtonLabel !== ''" />
</div>
