import { Directive, ElementRef, AfterViewInit } from "@angular/core";

@Directive({
  selector: "[ignite-iframe-clicker]",
})
export class IframeClickerDirective implements AfterViewInit {
  constructor(private el: ElementRef<HTMLIFrameElement>) {}

  ngAfterViewInit(): void {
    this.el.nativeElement.addEventListener("load", () => {
      this.el.nativeElement.contentDocument.addEventListener("click", () => {
        this.el.nativeElement.click();
      });
    });
  }
}
