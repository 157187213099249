import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChildren,
  HostListener,
  ViewChild,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { IgniteDataSource, PageResponse, SearchCriteria, SearchFilterGroup, Utility } from "@ignite/ignite-common";
import { IgniteSearchFilterComponent } from "../ignite-search-filter/ignite-search-filter.component";

@Component({
  selector: "ignite-search",
  templateUrl: "./ignite-search.component.html",
  styleUrls: ["./ignite-search.component.scss"],
})
export class IgniteSearchComponent implements OnInit, OnChanges {
  @Input() dataSource: IgniteDataSource<any>;
  @Input() placeholder: string;
  @Input() searchCriteria: SearchCriteria = new SearchCriteria();
  @Output() search: EventEmitter<any> = new EventEmitter();
  @ViewChildren(IgniteSearchFilterComponent) filters: IgniteSearchFilterComponent[];
  @ViewChild("searchInput") inputCtrl: ElementRef<HTMLInputElement>;
  private filtersQuery: string;
  private searchText: string;
  private _hideSearch = false;
  showNoResults = false;

  get hideSearch(): boolean {
    return this._hideSearch;
  }

  constructor(private el: ElementRef<Element>) {}

  ngOnInit(): void {
    if (this.dataSource != null) {
      this.dataSource.pageResponse$.subscribe({
        next: (pr: PageResponse<unknown>) => {
          this.showNoResults = pr.totalCount === 0;
        },
      });
    }

    if (Utility.IsEmptyOrNull(this.placeholder)) {
      this.placeholder = "Search";
    }
    if (this.searchCriteria == null) {
      this.searchCriteria = new SearchCriteria();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filtersQuery = SearchCriteria.getFiltersQuery(this.searchCriteria.filterGroups);
    this.searchText = this.searchCriteria.searchText;

    const mainContainer = this.el.nativeElement.ownerDocument.getElementsByClassName("ignite-main-content-container");
    if (mainContainer.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (mainContainer[0] as any).onscroll = () => this.closeFilterPopups();
    }
  }

  onSearch(): void {
    setTimeout(() => {
      if (
        Utility.IsNull(this.filters.find((f) => f.isOpened)) &&
        (this.searchText !== this.searchCriteria.searchText ||
          this.filtersQuery !== SearchCriteria.getFiltersQuery(this.searchCriteria.filterGroups))
      ) {
        this.searchText = this.searchCriteria.searchText;
        this.filtersQuery = SearchCriteria.getFiltersQuery(this.searchCriteria.filterGroups);
        this.search.emit(this.searchCriteria);
      }
    }, 500);
  }

  onScroll(): void {
    this.closeFilterPopups();
  }

  onFilterOpen(filterGroup: SearchFilterGroup): void {
    this.closeFilterPopups(filterGroup);
  }

  onFilterClose(filterGroup: SearchFilterGroup): void {
    this.onSearch();
  }

  onGridSelect($event): void {
    this._hideSearch = !Utility.IsNull($event);
  }

  clearAllFilters(): void {
    this.searchCriteria.searchText = "";
    this.filters.forEach((f) => f.clearFilter());
    this.onSearch();
  }

  clearText(): void {
    this.searchCriteria.searchText = "";
    this.inputCtrl.nativeElement.focus();
  }

  @HostListener("window:resize")
  onResize(): void {
    this.closeFilterPopups();
  }

  @HostListener("window:click")
  onWindowClick(): void {
    this.closeFilterPopups();
  }

  private closeFilterPopups(current: SearchFilterGroup = null) {
    const filter = Utility.IsNull(current)
      ? this.filters.find((f) => f.isOpened)
      : this.filters.find((f) => f.filterGroup.id !== current.id && f.isOpened);
    if (!Utility.IsNull(filter)) {
      filter.togglePopup();
    }
  }
}
