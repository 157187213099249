import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ignite-panel-stepper-item",
  templateUrl: "./ignite-panel-stepper-item.component.html",
  styleUrls: ["./ignite-panel-stepper-item.component.scss"],
})
export class IgnitePanelStepperItemComponent implements OnInit {
  @Input() completed: boolean;
  @Input() itemId: string;
  @Input() isLast: boolean;
  @Input() lastStepperBackgroundColor: string = "#ffffff";
  @Input() isLastOfAll: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCompletedChanged = new EventEmitter<{ id: string; completed: boolean }>();
  tooltip: string;
  isHoverIcon: boolean;

  ngOnInit(): void {
    this.updateCompletedTooltip();
  }

  changeCompleted(): void {
    this.completed = !this.completed;
    this.onCompletedChanged.emit({ id: this.itemId, completed: this.completed });
    this.updateCompletedTooltip();
  }

  updateCompletedTooltip(): void {
    if (this.completed) {
      this.tooltip = "Mark as\nincomplete";
    } else {
      this.tooltip = "Mark as\ncomplete";
    }
  }
}
