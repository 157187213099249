/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormControl, Validators, UntypedFormGroup, ValidatorFn, UntypedFormBuilder } from "@angular/forms";
import {
  AgeValidator,
  IgniteBaseComponent,
  IgniteGridColumnDef,
  IgniteGridFieldDef,
  IgnitePanelMenuOption,
  IgniteSnackbarComponent,
  IgniteSnackbarData,
  IgniteSnackbarType,
  UIControlType,
  SaveDialogComponent,
  MustSelectValidator,
} from "@ignite/ui-common";
import {
  QueryParams,
  SearchCriteria,
  SearchFilterGroup,
  SearchFilter,
  SortCriteria,
  DateUtility,
  PersonPlaceNameValidator,
} from "@ignite/ignite-common";
import { LocationDataSource } from "../../datasources/locationDataSource";
import { LocationService } from "../../services/location-service";
import { Observable, Subject, takeUntil, tap, timer } from "rxjs";
import { PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MyCustomValidator } from "./my-custom.validator";
import { PaymentDataSource } from "../../datasources/paymentDataSource";
import { PaymentService } from "../../services/payment-service";
import { Payment } from "../../models/payment";
import { MatDialog } from "@angular/material/dialog";

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-form-fields",
  templateUrl: "./form-fields.component.html",
  styleUrls: ["./form-fields.component.scss"],
})
export class FormFieldsComponent extends IgniteBaseComponent implements OnInit, AfterViewChecked {
  @ViewChild("templateBottomSheet") TemplateBottomSheet: TemplateRef<any>;
  @ViewChild("templateBottomSheet2") TemplateBottomSheet2: TemplateRef<any>;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild("customContent") customContent: ElementRef;

  progressValue = 0;
  locationDS: LocationDataSource;
  query = new QueryParams(1, 10, undefined, "name");
  searchCriteria = new SearchCriteria();
  tabStyleSelected = "0";

  gridColumns: IgniteGridColumnDef[];
  gridFields: IgniteGridFieldDef[];

  displayedColumns: string[] = ["position", "name", "weight", "symbol"];

  form: UntypedFormGroup;

  email = new UntypedFormControl("", [Validators.required, Validators.email]);
  foods: Food[] = [
    { value: "steak-0", viewValue: "Steak" },
    { value: "pizza-1", viewValue: "Pizza" },
    { value: "tacos-2", viewValue: "Tacos" },
  ];
  tabStyles: Food[] = [
    { value: "0", viewValue: "Button" },
    { value: "1", viewValue: "Menu" },
  ];
  myControl = new UntypedFormControl();
  options: any[] = [
    { value: "01", text: "One" },
    { value: "02", text: "Two" },
    { value: "03", text: "Three" },
  ];

  countries = [
    { value: "01", text: "Angola" },
    { value: "02", text: "Armenia" },
    { value: "021", text: "Australia" },
    { value: "03", text: "Brazil" },
    { value: "04", text: "Bulgaria" },
    { value: "05", text: "Cabo Verde" },
    { value: "06", text: "Costa Rica" },
    { value: "07", text: "France" },
    { value: "08", text: "Guinea-Bissau" },
    { value: "09", text: "Italy" },
    { value: "10", text: "Portugal" },
    { value: "11", text: "Macau" },
    { value: "12", text: "Zimbabwe" },
    { value: "99", text: "Canada" },
  ];

  buffet: Food[] = [{ value: "buffet-0", viewValue: "Buffet" }];

  countryCallingCodes = [
    { name: "Afghanistan", callingCode: "93", iso2: "AF" },
    { name: "Albania", callingCode: "355", iso2: "AL" },
    { name: "Algeria", callingCode: "213", iso2: "DZ" },
    { name: "American Samoa", callingCode: "1684", iso2: "AS" },
    { name: "Andorra", callingCode: "376", iso2: "AD" },
    { name: "Angola", callingCode: "244", iso2: "AO" },
    { name: "Anguilla", callingCode: "1264", iso2: "AI" },
    { name: "Antarctica", callingCode: "672", iso2: "AQ" },
    { name: "Antigua and Barbuda", callingCode: "1268", iso2: "AG" },
    { name: "Argentina", callingCode: "54", iso2: "AR" },
    { name: "Armenia", callingCode: "374", iso2: "AM" },
    { name: "Aruba", callingCode: "297", iso2: "AW" },
    { name: "Australia", callingCode: "61", iso2: "AU" },
    { name: "Austria", callingCode: "43", iso2: "AT" },
    { name: "Azerbaijan", callingCode: "994", iso2: "AZ" },
    { name: "Bahamas", callingCode: "1242", iso2: "BS" },
    { name: "Bahrain", callingCode: "973", iso2: "BH" },
    { name: "Bangladesh", callingCode: "880", iso2: "BD" },
    { name: "Barbados", callingCode: "1246", iso2: "BB" },
    { name: "Belarus", callingCode: "375", iso2: "BY" },
    { name: "Belgium", callingCode: "32", iso2: "BE" },
    { name: "Belize", callingCode: "501", iso2: "BZ" },
    { name: "Benin", callingCode: "229", iso2: "BJ" },
    { name: "Bermuda", callingCode: "1441", iso2: "BM" },
    { name: "Bhutan", callingCode: "975", iso2: "BT" },
    { name: "Bolivia", callingCode: "591", iso2: "BO" },
    { name: "Bosnia and Herzegovina", callingCode: "387", iso2: "BA" },
    { name: "Botswana", callingCode: "267", iso2: "BW" },
    { name: "Bouvet Island", callingCode: "47", iso2: "BV" },
    { name: "Brazil", callingCode: "55", iso2: "BR" },
    { name: "British Indian Ocean Territory", callingCode: "246", iso2: "IO" },
    { name: "Brunei Darussalam", callingCode: "673", iso2: "BN" },
    { name: "Bulgaria", callingCode: "359", iso2: "BG" },
    { name: "Burkina Faso", callingCode: "226", iso2: "BF" },
    { name: "Burundi", callingCode: "257", iso2: "BI" },
    { name: "Cambodia", callingCode: "855", iso2: "KH" },
    { name: "Cameroon", callingCode: "237", iso2: "CM" },
    { name: "Canada", callingCode: "1", iso2: "CA" },
    { name: "Cape Verde", callingCode: "238", iso2: "CV" },
    { name: "Cayman Islands", callingCode: "1345", iso2: "KY" },
    { name: "Central African Republic", callingCode: "236", iso2: "CF" },
    { name: "Chad", callingCode: "235", iso2: "TD" },
    { name: "Chile", callingCode: "56", iso2: "CL" },
    { name: "China", callingCode: "86", iso2: "CN" },
    { name: "Christmas Island", callingCode: "61", iso2: "CX" },
    { name: "Cocos Islands", callingCode: "61", iso2: "CC" },
    { name: "Colombia", callingCode: "57", iso2: "CO" },
    { name: "Comoros", callingCode: "269", iso2: "KM" },
    { name: "Congo, Republic of the", callingCode: "242", iso2: "CG" },
    { name: "Congo, The Democratic Republic", callingCode: "243", iso2: "CD" },
    { name: "Cook Islands ", callingCode: "682", iso2: "CK" },
    { name: "Costa Rica", callingCode: "506", iso2: "CR" },
    { name: "Cote d'Ivoire", callingCode: "225", iso2: "CI" },
    { name: "Croatia", callingCode: "385", iso2: "HR" },
    { name: "Cuba", callingCode: "53", iso2: "CU" },
    { name: "Cyprus", callingCode: "357", iso2: "CY" },
    { name: "Czech Republic", callingCode: "420", iso2: "CZ" },
    { name: "Denmark", callingCode: "45", iso2: "DK" },
    { name: "Djibouti", callingCode: "253", iso2: "DJ" },
    { name: "Dominica", callingCode: "1767", iso2: "DM" },
    { name: "Dominican Republic", callingCode: "1", iso2: "DO" },
    { name: "Ecuador", callingCode: "593", iso2: "EC" },
    { name: "Egypt", callingCode: "20", iso2: "EG" },
    { name: "El Salvador", callingCode: "503", iso2: "SV" },
    { name: "Equatorial Guinea", callingCode: "240", iso2: "GQ" },
    { name: "Eritrea", callingCode: "291", iso2: "ER" },
    { name: "Estonia", callingCode: "372", iso2: "EE" },
    { name: "Eswatini", callingCode: "268", iso2: "SZ" },
    { name: "Ethiopia", callingCode: "251", iso2: "ET" },
    { name: "Falkland Islands", callingCode: "500", iso2: "FK" },
    { name: "Faroe Islands", callingCode: "298", iso2: "FO" },
    { name: "Fiji", callingCode: "679", iso2: "FJ" },
    { name: "Finland", callingCode: "358", iso2: "FI" },
    { name: "France", callingCode: "33", iso2: "FN" },
    { name: "French Guiana", callingCode: "594", iso2: "GF" },
    { name: "French Polynesia", callingCode: "689", iso2: "PF" },
    { name: "French Southern Territories", callingCode: "262", iso2: "YT" },
    { name: "Gabon", callingCode: "241", iso2: "GA" },
    { name: "Gambia", callingCode: "220", iso2: "GM" },
    { name: "Georgia", callingCode: "995", iso2: "GE" },
    { name: "Germany", callingCode: "49", iso2: "DE" },
    { name: "Ghana", callingCode: "233", iso2: "GH" },
    { name: "Gibraltar", callingCode: "350", iso2: "GI" },
    { name: "Greece", callingCode: "30", iso2: "GR" },
    { name: "Greenland", callingCode: "299", iso2: "GL" },
    { name: "Grenada", callingCode: "1473", iso2: "GD" },
    { name: "Guadeloupe", callingCode: "590", iso2: "GP" },
    { name: "Guam", callingCode: "1671", iso2: "GU" },
    { name: "Guatemala", callingCode: "502", iso2: "GT" },
    { name: "Guinea", callingCode: "224", iso2: "GN" },
    { name: "Guinea-Bissau", callingCode: "245", iso2: "GW" },
    { name: "Guyana", callingCode: "592", iso2: "GY" },
    { name: "Haiti", callingCode: "509", iso2: "HT" },
    { name: "Heard Island / McDonald Islands", callingCode: "672", iso2: "HM" },
    { name: "Honduras", callingCode: "504", iso2: "HN" },
    { name: "Hong Kong SAR, China", callingCode: "852", iso2: "HK" },
    { name: "Hungary", callingCode: "36", iso2: "HU" },
    { name: "Iceland", callingCode: "354", iso2: "IS" },
    { name: "India", callingCode: "91", iso2: "IN" },
    { name: "Indonesia", callingCode: "62", iso2: "ID" },
    { name: "Iran", callingCode: "98", iso2: "IR" },
    { name: "Iraq", callingCode: "964", iso2: "IQ" },
    { name: "Ireland", callingCode: "353", iso2: "IE" },
    { name: "Israel", callingCode: "972", iso2: "IL" },
    { name: "Italy", callingCode: "39", iso2: "IT" },
    { name: "Jamaica", callingCode: "1", iso2: "JM" },
    { name: "Japan ", callingCode: "81", iso2: "JP" },
    { name: "Jordan", callingCode: "962", iso2: "JO" },
    { name: "Kazakhstan", callingCode: "7", iso2: "KZ" },
    { name: "Kenya", callingCode: "254", iso2: "KE" },
    { name: "Kiribati", callingCode: "686", iso2: "KI" },
    { name: "Korea, North", callingCode: "850", iso2: "KP" },
    { name: "Korea, South", callingCode: "82", iso2: "KR" },
    { name: "Kuwait", callingCode: "965", iso2: "KW" },
    { name: "Kyrgyzstan", callingCode: "996", iso2: "KG" },
    { name: "Lao Peoples Democratic Republic", callingCode: "856", iso2: "LA" },
    { name: "Latvia", callingCode: "371", iso2: "LV" },
    { name: "Lebanon", callingCode: "961", iso2: "LB" },
    { name: "Lesotho", callingCode: "266", iso2: "LS" },
    { name: "Liberia", callingCode: "231", iso2: "LR" },
    { name: "Libya", callingCode: "218", iso2: "LY" },
    { name: "Liechtenstein", callingCode: "423", iso2: "LI" },
    { name: "Lithuania", callingCode: "370", iso2: "LT" },
    { name: "Luxembourg", callingCode: "370", iso2: "LU" },
    { name: "Macao SAR, China", callingCode: "853", iso2: "MO" },
    { name: "Madagascar", callingCode: "261", iso2: "MG" },
    { name: "Malawi", callingCode: "265", iso2: "MW" },
    { name: "Malaysia", callingCode: "60", iso2: "MY" },
    { name: "Maldives", callingCode: "960", iso2: "MV" },
    { name: "Mali", callingCode: "223", iso2: "ML" },
    { name: "Malta", callingCode: "356", iso2: "MT" },
    { name: "Marshall Islands", callingCode: "692", iso2: "MH" },
    { name: "Martinique", callingCode: "596", iso2: "MQ" },
    { name: "Mauritania", callingCode: "222", iso2: "MR" },
    { name: "Mauritius", callingCode: "230", iso2: "MU" },
    { name: "Mayotte", callingCode: "262", iso2: "YT" },
    { name: "Mexico", callingCode: "52", iso2: "MX" },
    { name: "Micronesia", callingCode: "691", iso2: "FM" },
    { name: "Moldova", callingCode: "373", iso2: "MD" },
    { name: "Monaco", callingCode: "377", iso2: "MC" },
    { name: "Mongolia", callingCode: "976", iso2: "MN" },
    { name: "Montenegro", callingCode: "382", iso2: "ME" },
    { name: "Montserrat", callingCode: "1", iso2: "MS" },
    { name: "Morocco", callingCode: "212", iso2: "MA" },
    { name: "Mozambique", callingCode: "258", iso2: "MZ" },
    { name: "Myanmar", callingCode: "95", iso2: "MM" },
    { name: "Namibia", callingCode: "264", iso2: "NA" },
    { name: "Nauru", callingCode: "674", iso2: "NR" },
    { name: "Nepal", callingCode: "977", iso2: "NP" },
    { name: "Netherlands", callingCode: "31", iso2: "NL" },
    { name: "New Caledonia", callingCode: "687", iso2: "NC" },
    { name: "New Zealand", callingCode: "64", iso2: "NZ" },
    { name: "Nicaragua", callingCode: "505", iso2: "NI" },
    { name: "Niger", callingCode: "227", iso2: "NE" },
    { name: "Nigeria", callingCode: "234", iso2: "NG" },
    { name: "Niue", callingCode: "683", iso2: "NU" },
    { name: "Norfolk Island", callingCode: "672", iso2: "NF" },
    { name: "North Macedonia", callingCode: "389", iso2: "MK" },
    { name: "Northern Mariana Islands", callingCode: "1", iso2: "MP" },
    { name: "Norway", callingCode: "47", iso2: "NO" },
    { name: "Oman", callingCode: "968", iso2: "OM" },
    { name: "Pakistan", callingCode: "92", iso2: "PK" },
    { name: "Palau", callingCode: "680", iso2: "PW" },
    { name: "Palestine, State of", callingCode: "970", iso2: "PS" },
    { name: "Panama", callingCode: "507", iso2: "PA" },
    { name: "Papua New Guinea", callingCode: "675", iso2: "PG" },
    { name: "Paraguay", callingCode: "595", iso2: "PY" },
    { name: "Peru", callingCode: "51", iso2: "PE" },
    { name: "Philippines", callingCode: "63", iso2: "PH" },
    { name: "Pitcairn", callingCode: "64", iso2: "PN" },
    { name: "Poland", callingCode: "48", iso2: "PL" },
    { name: "Portugal", callingCode: "351", iso2: "PT" },
    { name: "Puerto Rico", callingCode: "1", iso2: "PR" },
    { name: "Qatar", callingCode: "974", iso2: "QA" },
    { name: "Romania", callingCode: "40", iso2: "RO" },
    { name: "Russian Federation", callingCode: "7", iso2: "RU" },
    { name: "Rwanda", callingCode: "250", iso2: "RW" },
    { name: "Réunion", callingCode: "262", iso2: "RE" },
    { name: "Saint Helena", callingCode: "290", iso2: "SH" },
    { name: "Saint Kitts and Nevis", callingCode: "1869", iso2: "KN" },
    { name: "Saint Lucia", callingCode: "1758", iso2: "LC" },
    { name: "Saint Martin", callingCode: "590", iso2: "MF" },
    { name: "Saint Pierre and Miquelon", callingCode: "508", iso2: "PM" },
    { name: "Saint Vincent and Grenadines", callingCode: "1784", iso2: "VC" },
    { name: "Samoa", callingCode: "685", iso2: "WS" },
    { name: "San Marino", callingCode: "378", iso2: "SM" },
    { name: "Sao Tome and Principe", callingCode: "239", iso2: "ST" },
    { name: "Saudi Arabia", callingCode: "966", iso2: "SA" },
    { name: "Senegal", callingCode: "221", iso2: "SN" },
    { name: "Serbia", callingCode: "381", iso2: "RS" },
    { name: "Seychelles", callingCode: "248", iso2: "SC" },
    { name: "Sierra Leone", callingCode: "232", iso2: "SL" },
    { name: "Singapore", callingCode: "65", iso2: "SG" },
    { name: "Slovakia", callingCode: "421", iso2: "SK" },
    { name: "Slovenia", callingCode: "386", iso2: "SI" },
    { name: "Solomon Islands", callingCode: "677", iso2: "SB" },
    { name: "Somalia", callingCode: "252", iso2: "SO" },
    { name: "South Africa", callingCode: "27", iso2: "ZA" },
    { name: "South Georgia / Sandwich Islands", callingCode: "500", iso2: "GS" },
    { name: "South Sudan", callingCode: "211", iso2: "SS" },
    { name: "Spain", callingCode: "34", iso2: "ES" },
    { name: "Sri Lanka", callingCode: "94", iso2: "LK" },
    { name: "Sudan", callingCode: "249", iso2: "SD" },
    { name: "Suriname", callingCode: "597", iso2: "SR" },
    { name: "Svalbard and Jan Mayen", callingCode: "47", iso2: "SJ" },
    { name: "Sweden", callingCode: "46", iso2: "SE" },
    { name: "Switzerland", callingCode: "41", iso2: "CH" },
    { name: "Syrian Arab Republic", callingCode: "963", iso2: "SY" },
    { name: "Taiwan, China", callingCode: "886", iso2: "TW" },
    { name: "Tajikistan", callingCode: "992", iso2: "TJ" },
    { name: "Tanzania", callingCode: "255", iso2: "TZ" },
    { name: "Thailand", callingCode: "66", iso2: "TH" },
    { name: "Timor-Leste", callingCode: "670", iso2: "TL" },
    { name: "Togo", callingCode: "228", iso2: "TG" },
    { name: "Tokelau", callingCode: "690", iso2: "TK" },
    { name: "Tonga", callingCode: "676", iso2: "TG" },
    { name: "Trinidad and Tobago", callingCode: "1", iso2: "TT" },
    { name: "Tunisia", callingCode: "216", iso2: "TN" },
    { name: "Turkey", callingCode: "90", iso2: "TR" },
    { name: "Turkmenistan", callingCode: "993", iso2: "TM" },
    { name: "Turks and Caicos Islands", callingCode: "1649", iso2: "TC" },
    { name: "Tuvalu", callingCode: "688", iso2: "TV" },
    { name: "US Minor Outlying Islands", callingCode: "246", iso2: "UM" },
    { name: "Uganda", callingCode: "256", iso2: "UG" },
    { name: "Ukraine", callingCode: "380", iso2: "UA" },
    { name: "United Arab Emirates", callingCode: "971", iso2: "AE" },
    { name: "United Kingdom", callingCode: "44", iso2: "GB" },
    { name: "United States", callingCode: "1", iso2: "US" },
    { name: "Uruguay", callingCode: "598", iso2: "UY" },
    { name: "Uzbekistan", callingCode: "998", iso2: "UZ" },
    { name: "Vanuatu", callingCode: "678", iso2: "VU" },
    { name: "Vatican City State", callingCode: "39", iso2: "VA" },
    { name: "Venezuela", callingCode: "58", iso2: "VE" },
    { name: "Viet Nam", callingCode: "84", iso2: "VN" },
    { name: "Virgin Islands, British", callingCode: "1284", iso2: "VG" },
    { name: "Virgin Islands, US", callingCode: "1340", iso2: "VI" },
    { name: "Wallis and Futuna Islands", callingCode: "681", iso2: "WF" },
    { name: "Western Sahara", callingCode: "212", iso2: "EH" },
    { name: "Yemen", callingCode: "967", iso2: "YE" },
    { name: "Zambia", callingCode: "260", iso2: "SM" },
    { name: "Zimbabwe", callingCode: "263", iso2: "ZW" },
  ];

  panelMenuOptions: IgnitePanelMenuOption[] = [];

  customColumns: IgniteGridColumnDef[];
  customFields: IgniteGridFieldDef[];
  customDS: PaymentDataSource;

  constructor(
    private cdRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private locationService: LocationService,
    private bottomSheet: MatBottomSheet,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  getErrorMessage(): string {
    if (this.email.hasError("required")) {
      return "You must enter a value";
    }

    return this.email.hasError("email") ? "Not a valid email" : "";
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      testInput: [""],
      phone: [""],
      radiob: ["", [MustSelectValidator()]],
      dob: ["", [Validators.required, AgeValidator()]],
      regularDate: ["", [Validators.required]],
    });

    const panelOption = new IgnitePanelMenuOption("Follow", "Unfollow", "fa-user-plus", "fa-user-times", "fa-user-check");
    panelOption.selected = true;
    panelOption.toggleCallback = (selected: boolean) => {
      return new Observable<any>();
    };
    this.panelMenuOptions.push(panelOption);

    this.searchCriteria.id = "TestTakerGrid";
    this.searchCriteria.searchText = "John Doe";
    this.searchCriteria.filterGroups = [];
    let sfg = new SearchFilterGroup("TTG-Location", "Location", "location", "==");
    sfg.filters = [];
    this.countries.forEach((c) => {
      sfg.filters.push(new SearchFilter(c.value, c.text));
    });
    this.searchCriteria.filterGroups.push(sfg);

    sfg = new SearchFilterGroup("TTG-Watch", "Watch", "watch", "==");
    sfg.filters = [new SearchFilter("true", "Watching"), new SearchFilter("false", "Not watching", true)];
    this.searchCriteria.filterGroups.push(sfg);

    sfg = new SearchFilterGroup("TTG-Status", "Status", "status", "==");
    sfg.filters = [
      new SearchFilter("Scored", "Scored received"),
      new SearchFilter("Match", "Match found"),
      new SearchFilter("ConsentPending", "Pending parental consent and approval"),
    ];
    this.searchCriteria.filterGroups.push(sfg);

    this.gridColumns = [];
    this.gridColumns.push(
      {
        header: "Name",
        field: "main.name",
        widthPercent: "90",
        sortable: true,
        sortDirection: "asc",
        isPrimarySort: true,
      },
      {
        header: "Date",
        field: "date",
        widthPercent: "10",
        sortable: true,
        sortDirection: "asc",
        isPrimarySort: false,
      }
    );

    this.gridFields = [];
    this.gridFields.push(
      {
        name: "date",
        label: "Date created",
        controlType: UIControlType.InputDate,
      },
      {
        name: "main.name",
        label: "Name",
        placeHolder: "The name of the location",
        controlType: UIControlType.InputText,
        required: true,
        customValidators: [
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            validator: new PersonPlaceNameValidator().validate.bind(this),
            customMessages: [{ key: "invalidChars", message: "Name contains invalid characters" }],
          },
        ],
      },
      {
        name: "streetAddress",
        label: "Street Address",
        placeHolder: "The location street address",
        controlType: UIControlType.InputText,
        required: true,
        customValidators: [
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            validator: new PersonPlaceNameValidator().validate.bind(this),
            customMessages: [{ key: "invalidChars", message: "Name contains invalid characters" }],
          },
        ],
      },
      {
        name: "role",
        label: "User Role",
        placeHolder: "Tue user's role",
        controlType: UIControlType.DropdownList,
        required: false,
        selectOptions: [
          { value: "01", text: "Administrator" },
          { value: "02", text: "Partner" },
          { value: "03", text: "Test Taker" },
        ],
        customValidators: [
          {
            validator: new MyCustomValidator().validate.bind(this) as ValidatorFn,
            customMessages: [],
          },
        ],
      },
      {
        name: "stateProvince",
        label: "State/Province",
        placeHolder: "State or Province",
        controlType: UIControlType.MultiSelect,
        required: false,
        selectOptions: [
          { value: "*", text: "All States" },
          { value: "01", text: "New South Wales" },
          { value: "02", text: "Victoria" },
          { value: "03", text: "Western Australia" },
          { value: "04", text: "Newfoundland and Labrador" },
          { value: "05", text: "Northwest Territories" },
          { value: "06", text: "Nova Scotia" },
          { value: "07", text: "Nunavut" },
          { value: "08", text: "Ontario" },
          { value: "09", text: "Saskatchewan" },
          { value: "10", text: "Yukon" },
        ],
      },
      {
        name: "city",
        label: "City",
        placeHolder: "City",
        controlType: UIControlType.InputText,
        required: true,
      },
      {
        name: "postalCode",
        label: "Zip/Postal Code",
        placeHolder: "The zip or postal code",
        controlType: UIControlType.InputText,
        required: false,
      },
      {
        name: "country",
        label: "Country",
        placeHolder: "The country",
        controlType: UIControlType.Autocomplete,
        required: true,
        selectOptions: this.countries,
      },
      {
        name: "phone",
        label: "Phone Number",
        placeHolder: "The phone number of this location",
        controlType: UIControlType.InputTelephone,
        countryCallingCodes: this.countryCallingCodes,
        required: false,
      }
    );

    this.locationDS = new LocationDataSource(this.locationService);
    this.loadData();

    this.customColumns = [
      {
        header: "Date",
        field: ["date"],
        sortable: true,
        sortDirection: "asc",
        isPrimarySort: true,
        widthPercent: "60",
      },
      { header: "Status", field: "status", widthPercent: "20", shownWhenSelected: true },
      { header: "Order Number", field: "orderNumber", widthPercent: "20", alignment: "right", shownWhenSelected: true },
    ];
    this.customFields = [
      {
        name: "date",
        controlType: UIControlType.InputDate,
      },
      {
        name: "status",
        controlType: UIControlType.InputText,
      },
      {
        name: "orderNumber",
        controlType: UIControlType.InputText,
      },
    ];
    this.customDS = new PaymentDataSource(this.paymentService);
    this.customDS.loadPayments(new QueryParams());
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
    this.form.get("radio")?.markAsDirty();
    this.form.get("radio")?.markAsTouched();
  }

  private loadData() {
    this.locationDS.loadLocations(this.query);
  }

  onAdd(): void {
    this.form.get("dob")?.updateValueAndValidity();
    this.form.get("radiob")?.markAsDirty();
    this.form.get("radiob")?.updateValueAndValidity();

    const stopPolling$ = new Subject<void>();
    const stopTime = DateUtility.now.add(20, "seconds");

    const archiveUser$ = timer(0, 3000).pipe(
      takeUntil(stopPolling$),
      tap(() => {
        if (DateUtility.now.isAfter(stopTime)) {
          stopPolling$.next();
        }
      })
    );
    this.dialog
      .open(SaveDialogComponent, {
        maxWidth: "290px",
        maxHeight: "310px",
        disableClose: true,
        data: {
          isSaved$: archiveUser$,
          savingMessage: "Adding account...",
          savedMessage: "Account has been added.",
        },
      })
      .afterClosed()
      .subscribe();
  }

  onTabChange($event: MatTabChangeEvent): void {
    if ($event.index === 1) {
      this.progressValue = 0;
      setInterval(() => {
        if (this.progressValue >= 100) {
          this.progressValue = 0;
        } else {
          this.progressValue += 2;
        }
      }, 200);
    }
  }

  openTemplateSheetMenu(sheet = 1) {
    if (sheet === 1) {
      this.bottomSheet.open(this.TemplateBottomSheet);
    } else {
      this.bottomSheet.open(this.TemplateBottomSheet2);
    }
  }

  closeTemplateSheetMenu() {
    this.bottomSheet.dismiss();
  }

  onPaging($event: PageEvent) {
    this.query.page = $event.pageIndex;
    this.query.pageSize = $event.pageSize;
    this.loadData();
  }

  onSort($event: SortCriteria) {
    this.query.sort = SortCriteria.getSorts($event.sortColumns);
    this.loadData();
  }

  onSearch($event: SearchCriteria) {
    console.log($event.searchText + "---" + SearchCriteria.getFiltersQuery($event.filterGroups));
  }

  showSnackbar(type: string): void {
    if (type === "alert") {
      const ref = this.snackBar.openFromComponent(IgniteSnackbarComponent, {
        data: new IgniteSnackbarData("Something went wrong. Try again", IgniteSnackbarType.Alert, "RETRY"),
      });
      ref.onAction().subscribe(() => alert("do retry"));
    }
    if (type === "success") {
      this.snackBar.openFromComponent(IgniteSnackbarComponent, {
        data: new IgniteSnackbarData("Everything is good. Please proceed.", IgniteSnackbarType.Success),
      });
    }
  }

  onCustomSelect(item: Payment): void {
    // console.log(item);
  }

  formatter = Intl.NumberFormat(undefined, { currency: "USD", style: "currency" });

  getPrice(n: number): string {
    return this.formatter.format(n);
  }
}
