/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { QueryParams, PageResponse } from "@ignite/ignite-common";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  getPayments(params: QueryParams): Observable<PageResponse<any>> {
    const response: PageResponse<any> = new PageResponse<any>();
    response.items = [
      {
        id: "1",
        date: new Date("2023-02-14"),
        status: "Scored",
        orderNumber: "43456457",
        details: [
          {
            kind: "item",
            description: "PTE Academic",
            price: 203.39,
          },
          {
            kind: "item",
            description: "Official Guide to PTE Academic",
            price: 39.99,
          },
          {
            kind: "subtotal",
            description: "",
            price: 243.98,
          },
          {
            kind: "discount",
            description: "PTE Academic (Refund)",
            price: 203.39,
          },
          {
            kind: "total",
            description: "",
            price: 39.99,
          },
        ],
      },
      {
        id: "2",
        date: new Date("2023-03-20"),
        status: "Completed",
        orderNumber: "54224334",
        details: [
          {
            kind: "item",
            description: "PTE Academic",
            price: 0.0,
          },
          {
            kind: "item",
            description: "Gold Package",
            price: 0.0,
          },
          {
            kind: "total",
            description: "",
            price: 0.0,
          },
        ],
      },
      {
        id: "3",
        date: new Date("2023-04-12"),
        status: "Scheduled",
        orderNumber: "67435687",
        details: [
          {
            kind: "item",
            description: "PTE Academic",
            price: 203.39,
          },
          {
            kind: "total",
            description: "",
            price: 203.39,
          },
        ],
      },
    ];

    // response.items = [];

    return of(response);
  }
}
