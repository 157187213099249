import { Component, ContentChildren, QueryList } from "@angular/core";
import { IgniteSideMenuItemComponent } from "../ignite-side-menu-item/ignite-side-menu-item.component";

@Component({
  selector: "ignite-side-menu",
  templateUrl: "./ignite-side-menu.component.html",
  styleUrls: ["./ignite-side-menu.component.scss"],
})
export class IgniteSideMenuComponent {
  @ContentChildren(IgniteSideMenuItemComponent) tabs!: QueryList<IgniteSideMenuItemComponent>;
  activeComponent!: IgniteSideMenuItemComponent;
  isShow = false;

  ngAfterContentInit() {
    this.activeComponent = this.tabs.first;
  }

  activateTab(tab: IgniteSideMenuItemComponent) {
    this.activeComponent = tab;
  }

  comboClick() {
    this.isShow = !this.isShow;
  }

  comboItemClick(tab: IgniteSideMenuItemComponent) {
    this.activeComponent = tab;
    this.isShow = !this.isShow;
  }
}
