import { Directive, Input, Optional, HostBinding } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { Utility } from "@ignite/ignite-common";

@Directive({
  selector: "ignite-label[controlName]",
})
export class IgniteLabelDirective {
  @Input() id: string;
  @Input() controlName: string;

  constructor(@Optional() private parent: ControlContainer) {}

  @HostBinding("innerHTML")
  get controlValue() {
    if (!Utility.IsNull(this.parent)) {
      const html = this.parent.control.get(this.controlName).value as string;
      return Utility.IsEmptyOrNull(html) ? "" : `<div class="ignite-label">${html}</div>`;
    } else {
      return "";
    }
  }
}
