<div class="ignite-paginator-container" [class.isHidden]="hidePaginator || getItemsCount() === '0'">
  <div class="ignite-paginator-controls">
    <span *ngIf="!hidePageSize">
      <span id="ignite-paginator-rows-label-desktop" class="for-desktop" i18n>Rows per page:</span>
      <span id="ignite-paginator-rows-label-mobile" class="for-mobile" i18n>Rows:</span>
      <mat-select id="ignite-paginator" [(ngModel)]="pageSize" (selectionChange)="onPageSizeChange($event)">
        <mat-option *ngFor="let o of pageSizeOptions" [value]="o">
          {{ o }}
        </mat-option>
      </mat-select>
      <i class="far fa-chevron-down"></i>
    </span>
    <span *ngIf="hidePageSize">
      <span id="ignite-paginator-rows-label-desktop" class="for-desktop" i18n>Rows:</span>
      <span id="ignite-paginator-rows-label-mobile" class="for-mobile" i18n>Rows:</span>
    </span>
    <span id="ignite-paginator-count" class="ignite-paginator-count">{{ getItemsCount() }}</span>
    <span>
      <button
        id="ignite-paginator-prev-button"
        [disabled]="index < 2"
        mat-button
        title="Previous page"
        i18n-title
        (click)="onPaging('previous')"
      >
        <i class="fal fa-arrow-circle-left"></i>
      </button>
      <button
        id="ignite-paginator-next-button"
        [disabled]="!hasNextPage"
        mat-button
        title="Next page"
        i18n-title
        (click)="onPaging('next')"
      >
        <i class="fal fa-arrow-circle-right"></i>
      </button>
    </span>
  </div>
  <hr />
</div>
