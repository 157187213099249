<div id="dialog-save" class="dialog-save">
  <ignite-card>
    <div card-title>
      <span *ngIf="!isSaved" i18n>{{ savingMessage }}</span>
      <span *ngIf="isSaved" i18n>Changes have been<br />saved</span>
    </div>
    <div card-content>
      <mat-progress-bar ignite-progress-bar *ngIf="!isSaved" mode="indeterminate"></mat-progress-bar>
      <p *ngIf="isSaved" i18n>{{ data.savedMessage }}</p>
    </div>
  </ignite-card>

  <div class="ignite-dialog-buttons-container">
    <div class="ignite-buttons">
      <div class="ignite-button-wrapper">
        <button id="button_ok" mat-button class="ignite-button" color="primary" (click)="dialogRef.close(true)">OK</button>
      </div>
    </div>
  </div>
</div>
