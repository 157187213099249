<div class="ignite-body">
  <div [class.hidden]="(showMenu$ | async) === false">
    <ignite-toolbar [firstName]="currentUserFirstName" [lastName]="currentUserLastName" [profileOptions]="profileOptions"></ignite-toolbar>
    <ignite-menu>
      <ignite-menu-button label="Dashboard" link="dashboard"></ignite-menu-button>
      <ignite-menu-button label="Inputs" link="formfields"></ignite-menu-button>
      <ignite-menu-button label="Stepper" link="stepper" badgeText="New"></ignite-menu-button>
      <ignite-menu-button label="Menu" link="menu"></ignite-menu-button>
      <ignite-menu-button label="Solo Card" link="solo-card"></ignite-menu-button>
      <ignite-menu-button label="Help" link="help"></ignite-menu-button>
    </ignite-menu>
  </div>
  <div class="ignite-main-content-container" cdkScrollable>
    <div class="ignite-main-content">
      <router-outlet></router-outlet>
    </div>
    <ignite-footer></ignite-footer>
  </div>
</div>
