/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-test-taker-status",
  templateUrl: "./test-taker-status.component.html",
  styleUrls: ["./test-taker-status.component.scss"],
})
export class TestTakerStatusComponent {
  @Input() value: any;
}
