/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Directive, ElementRef, AfterViewInit, HostListener, OnInit, AfterContentInit } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[ignite-sidenav]",
})
export class IgniteSidenavDirective implements OnInit, AfterContentInit {
  constructor(private el: ElementRef, private host: MatDrawer) {}

  ngOnInit(): void {
    this.host.mode = "side";
  }

  ngAfterContentInit(): void {
    this.getScreenSize();
    const $el = this.el.nativeElement;
    $el.classList.add("ignite-sidenav");
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    if (window.innerWidth <= 1024) {
      this.host.mode = "over";
      void this.host.close();
    } else {
      this.host.mode = "side";
      void this.host.open();
    }
  }
}
