import { Component, Input, OnInit } from "@angular/core";
import { Utility } from "@ignite/ignite-common";

@Component({
  selector: "ignite-alert",
  templateUrl: "./ignite-alert.component.html",
  styleUrls: ["./ignite-alert.component.scss"],
})
export class IgniteAlertComponent implements OnInit {
  static nextId = 0;
  @Input() id: string;

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.id)) {
      this.id = `ignite-alert-${IgniteAlertComponent.nextId++}`;
    }
  }
}
