import { AbstractControl, ValidatorFn } from "@angular/forms";
import { IgniteSelectOption } from "../models/ignite-select-option";
import { Utility } from "@ignite/ignite-common";

export function RequireMatchValidator(items: IgniteSelectOption[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (Utility.IsEmptyOrNull(control.value)) {
      return null;
    }
    const value = typeof control.value === "string" ? control.value : (control.value as IgniteSelectOption)?.text;
    const index = items.findIndex((item) => {
      return new RegExp("^" + item.text + "$").test(value);
    });
    return index < 0 ? { unmatchedItem: { value } } : null;
  };
}
