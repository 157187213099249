export enum UIControlType {
  InputText,
  InputNumber,
  InputDate,
  InputPassword,
  InputTelephone,
  Checkbox,
  RadioButton,
  DropdownList,
  MultiSelect,
  Autocomplete,
  Label,
  Hidden,
}
