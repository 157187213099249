/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { AbstractControl, ValidatorFn } from "@angular/forms";
import { DateUtility, Utility } from "@ignite/ignite-common";

export function AgeValidator(): ValidatorFn {
  const calculateAge = (birthDate: Date): number => {
    const currentDate = new Date();
    if (!birthDate || birthDate >= currentDate) {
      return 0;
    }
    const birthYear = birthDate.getFullYear();
    const birthMonth = birthDate.getMonth();
    const birthDay = birthDate.getDate();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    let calculatedAge = currentYear - birthYear;

    if (currentMonth < birthMonth) {
      calculatedAge--;
    }
    if (birthMonth === currentMonth && currentDay < birthDay) {
      calculatedAge--;
    }
    return calculatedAge;
  };

  return (control: AbstractControl): { [key: string]: any } | null => {
    if (Utility.IsEmptyOrNull(control.value)) {
      return null;
    }
    const dateOfBirth = Date.parse(control.value);
    if (!dateOfBirth) {
      return null;
    }

    if (DateUtility.isAfter(dateOfBirth, new Date())) {
      return { inFuture: true };
    } else if (DateUtility.isBefore(dateOfBirth, DateUtility.now.add(-100, "years"))) {
      return { tooEarly: true };
    } else {
      const age = calculateAge(new Date(dateOfBirth));
      return 0 <= age && age < 16 ? { notLegalAge: true } : null;
    }
  };
}
