/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AbstractControl, ValidationErrors, Validator } from "@angular/forms";
import { Utility } from "@ignite/ignite-common";
export class MyCustomValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    if (control === null || control.parent === null) {
      return null;
    }

    const state = control.parent.get("stateProvince");
    if (control.value === "01") {
      // Yukon
      state.setValue(["*"]);
      state.disable();
    } else {
      const val = state.value;
      if (Utility.IsEmptyOrNull(val) || !Array.isArray(val) || (state.value.length === 1 && state.value[0] === "*")) {
        state.setValue([""]);
        state.enable();
      }
    }
    return null;
  }
}
