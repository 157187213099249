import { Component, OnInit, ViewChild } from "@angular/core";
import { Utility } from "@ignite/ignite-common";
import { NotepadDialogComponent } from "@ignite/ui-common";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  @ViewChild(NotepadDialogComponent) notepadDialog: NotepadDialogComponent;

  notifications = "";
  sideMenuOpened = true;
  notes = "";

  constructor() {}

  ngOnInit(): void {
    let notiCount = 1;
    setInterval(() => {
      if (notiCount >= 10) {
        this.notifications = "";
        notiCount = 1;
      } else {
        this.notifications = `${notiCount++}`;
      }
    }, 2000);
  }

  sideBarClick(msg: string): void {
    if (msg === "menu") {
      this.sideMenuOpened = !this.sideMenuOpened;
    }
    if (msg === "notePad") {
      if (!Utility.IsNull(this.notepadDialog)) {
        this.notepadDialog.toggle();
      }
    }
  }

  onNotesChanged(html: object) {
    console.log(html);
  }
}
