import { IgniteBaseComponent } from "./../ignite-base/ignite-base.component";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { IgniteDataSource, PageResponse, Utility } from "@ignite/ignite-common";
import { MatSelect } from "@angular/material/select";

@Component({
  selector: "ignite-paginator",
  templateUrl: "./ignite-paginator.component.html",
  styleUrls: ["./ignite-paginator.component.scss"],
})
export class IgnitePaginatorComponent extends IgniteBaseComponent implements OnInit {
  @Input() dataSource: IgniteDataSource<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() pageSize: number = 10;
  @Input() hidePageSize: boolean;
  @Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  itemsCount = 0;
  pageSizeOptions = [10, 20, 50, 100];
  index = 1;
  hasNextPage: boolean;
  private _hidePaginator = false;

  get hidePaginator(): boolean {
    return this._hidePaginator;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.dataSource != null) {
      this.dataSource.pageResponse$.subscribe({
        next: (pr: PageResponse<unknown>) => {
          if (pr.pageIndex > 0 && pr.pageIndex !== this.index) {
            this.index = pr.pageIndex;
          }
          const size = this.pageSize;
          const index = this.index;
          if (index > 1 && pr.totalCount === 0) {
            this.onPaging("previous");
          } else {
            this.itemsCount = pr.hasNextPage ? index * size + 1 : (index - 1) * size + pr.totalCount;
            this.hasNextPage = pr.hasNextPage;
          }
        },
      });
    }
  }

  onGridSelect($event): void {
    this._hidePaginator = !Utility.IsNull($event);
  }

  getItemsCount(): string {
    const start = (this.index - 1) * this.pageSize + 1;
    const end = this.index * this.pageSize > this.itemsCount ? this.itemsCount : this.index * this.pageSize;
    if (end === 0) {
      return "0";
    }
    return `${start} - ${end}`;
  }

  onPageSizeChange($event: MatSelect): void {
    const length = this.hasNextPage ? this.itemsCount - 1 : this.itemsCount;
    this.index = Math.ceil(length / $event.value);
    if (this.index < 1) {
      this.index = 1;
    }
    this.page.emit(this.getPageEvent());
  }

  onPaging(action: string): void {
    this.index = this.index + (action === "previous" ? -1 : 1);
    this.page.emit(this.getPageEvent());
  }

  private getPageEvent(): PageEvent {
    const ev = new PageEvent();
    ev.pageIndex = this.index;
    ev.pageSize = this.pageSize;
    ev.length = this.itemsCount;
    return ev;
  }
}
