<ignite-card>
  <div card-title i18n>Responsive menu</div>
  <div card-content i18n>Vertical tab and dropdown on small screens.</div>
</ignite-card>

<div style="margin-top: 30px">
  <h1>Side menu with groups</h1>
</div>

<ignite-side-menu>
  <ignite-side-menu-item title="General info" iconPath="assets/images/side-menu-svg/general-info.svg" [templateRef]="generalinfoTemplate">
    <ng-template #generalinfoTemplate>
      <div class="first-wrapper">
        <h3>General info</h3>
        <p>tab 1 description</p>
        <div class="first-image"></div>
      </div>
    </ng-template>
  </ignite-side-menu-item>

  <ignite-side-menu-item title="Study plan" iconPath="assets/images/side-menu-svg/study-plan.svg" [templateRef]="studyplanTemplate">
    <ng-template #studyplanTemplate>
      <div class="first-wrapper">
        <h3>Study plan</h3>
        <p>tab 1 description</p>
        <div class="first-image"></div>
      </div>
    </ng-template>
  </ignite-side-menu-item>

  <ignite-side-menu-item
    title="Speaking"
    iconPath="assets/images/side-menu-svg/speaking.svg"
    isTabGroup="true"
    groupTitle="Learn by skill"
    [templateRef]="speakingTemplate"
  >
    <ng-template #speakingTemplate>
      <div class="first-wrapper">
        <h3>Speaking</h3>
        <p>tab 1 description</p>
        <div class="first-image"></div>
      </div>
    </ng-template>
  </ignite-side-menu-item>

  <ignite-side-menu-item
    title="Writing"
    iconPath="assets/images/side-menu-svg/writing.svg"
    isTabGroup="true"
    [templateRef]="writingTemplate"
  >
    <ng-template #writingTemplate>
      <div class="first-wrapper">
        <h3>Writing</h3>
        <p>tab 1 description</p>
        <div class="first-image"></div>
      </div>
    </ng-template>
  </ignite-side-menu-item>

  <ignite-side-menu-item
    title="Reading"
    iconPath="assets/images/side-menu-svg/reading.svg"
    isTabGroup="true"
    [templateRef]="readingTemplate"
  >
    <ng-template #readingTemplate>
      <div class="second-wrapper">
        <h3>Reading</h3>
        <p>tab 2 description</p>
        <div class="second-image"></div>
      </div>
    </ng-template>
  </ignite-side-menu-item>

  <ignite-side-menu-item
    title="Listening"
    iconPath="assets/images/side-menu-svg/listening.svg"
    isTabGroup="true"
    [templateRef]="listeningTemplate"
  >
    <ng-template #listeningTemplate>
      <div class="first-wrapper">
        <h3>Listening</h3>
        <p>tab 1 description</p>
        <div class="first-image"></div>
      </div>
    </ng-template>
  </ignite-side-menu-item>
</ignite-side-menu>

<div style="margin-top: 30px">
  <h1>Side menu simple (no groups no icon)</h1>
</div>

<ignite-side-menu>
  <ignite-side-menu-item title="PTE Home A1" tabIcon="" [templateRef]="ptehomea1Template">
    <ng-template #ptehomea1Template>
      <div class="first-wrapper">
        <h3>PTE Home A1</h3>
        <p>tab 1 description</p>
        <div class="first-image"></div>
      </div>
    </ng-template>
  </ignite-side-menu-item>

  <ignite-side-menu-item title="PTE Home A2" tabIcon="" [templateRef]="ptehomea2Template">
    <ng-template #ptehomea2Template>
      <div class="first-wrapper">
        <h3>PTE Home A2</h3>
        <p>tab 1 description</p>
        <div class="first-image"></div>
      </div>
    </ng-template>
  </ignite-side-menu-item>

  <ignite-side-menu-item title="PTE Home B1" tabIcon="" [templateRef]="ptehomeb1Template">
    <ng-template #ptehomeb1Template>
      <div class="first-wrapper">
        <h3>PTE Home B1</h3>
        <p>tab 1 description</p>
        <div class="first-image"></div>
      </div>
    </ng-template>
  </ignite-side-menu-item>
</ignite-side-menu>
