<div class="ignite-toolbar-container">
  <div class="ignite-toolbar">
    <div class="toolbar-wrapper">
      <div class="toolbar-items">
        <img id="pearsonLogo" src="assets/images/pearsonpte-logo-b.svg" (click)="goHome()" alt="Pearson PTE" />
        <div *ngIf="firstName || lastName" class="user-profile-menu-container">
          <ignite-profile-menu [firstName]="firstName" [lastName]="lastName" [menuOptions]="profileOptions"></ignite-profile-menu>
        </div>
      </div>
    </div>
  </div>
</div>
