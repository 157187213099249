<div class="ignite-swiper-container">
  <div [attr.id]="id" class="ignite-swiper">
    <div
      class="ignite-swiper-cards"
      (mousedown)="swipe($event, 'start')"
      (mouseup)="swipe($event, 'end')"
      (touchstart)="swipe($event, 'start')"
      (touchend)="swipe($event, 'end')"
      #cardsContainer
    >
      <ng-content select="[swiper-card]"></ng-content>
    </div>
  </div>
  <div class="ignite-swiper-indicators">
    <i *ngFor="let c of cards; index as i" class="fas fa-circle" [class.selected]="i === currentIdx" (click)="selectCard(i)"></i>
  </div>
</div>
