import { Component, ContentChildren, Input, QueryList } from "@angular/core";
import { IgnitePanelStepperItemComponent } from "../ignite-panel-stepper-item/ignite-panel-stepper-item.component";

@Component({
  selector: "ignite-panel-stepper",
  templateUrl: "./ignite-panel-stepper.component.html",
  styleUrls: ["./ignite-panel-stepper.component.scss"],
})
export class IgnitePanelStepperComponent {
  @Input() panelNumber: string;
  @Input() panelTitle: string;
  @Input() collapsed = false;
  @Input() collapsible: boolean = true;
  @Input() completed = false;
  @ContentChildren(IgnitePanelStepperItemComponent) items!: QueryList<IgnitePanelStepperItemComponent>;

  onToggle($event: Event): void {
    $event.stopPropagation();
    if (!this.collapsible) {
      return;
    }
    this.collapsed = !this.collapsed;
  }

  ngAfterContentInit() {
    this.items.forEach((item) => {
      item.onCompletedChanged.subscribe(() => this.checkAllCompleted());
    });
    this.checkAllCompleted();
  }

  checkAllCompleted(): void {
    for (const item of this.items) {
      if (!item.completed) {
        this.completed = false;
        return;
      }
    }
    this.completed = true;
  }
}
