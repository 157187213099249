import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Utility } from "@ignite/ignite-common";
import { Observable, Subject } from "rxjs";
import { takeUntil, takeWhile } from "rxjs/operators";

export interface ProgressDialogData {
  isSaved$: Observable<boolean>;
  savedMessage: string;
  hideOkButton?: boolean;
  savingMessage: string;
}

@Component({
  selector: "progress-dialog",
  templateUrl: "./progress-dialog.component.html",
  styleUrls: ["./progress-dialog.component.scss"],
})
export class ProgressDialogComponent implements OnInit, OnDestroy {
  savingMessage: string;
  isSaved: boolean;
  progressValue: number;
  timer: ReturnType<typeof setInterval>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<ProgressDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ProgressDialogData) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.timer = null;
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.savingMessage = Utility.IsEmptyOrNull(this.data.savingMessage) ? "Saving changes..." : this.data.savingMessage;

    if (!Utility.IsNull(this.data.isSaved$)) {
      this.data.isSaved$
        .pipe(
          takeUntil(this.unsubscribe$),
          takeWhile((saved) => saved === false, true)
        )
        .subscribe({
          next: (saved) => {
            this.isSaved = saved;
          },
          error: () => {
            this.dialogRef.close(false);
          },
        });
    }

    this.dialogRef.beforeClosed().subscribe(() => (this.progressValue = 100));

    this.progressValue = 0;
    this.timer = setInterval(() => {
      if (this.progressValue < 99) {
        this.progressValue += this.progressValue < 70 ? 2 : 1;
      }
    }, 500);
  }

  public closeDialog() {
    this.progressValue = 100;
    setTimeout(() => this.dialogRef.close(), 500);
  }
}
