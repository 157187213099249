import { Component, ElementRef, Input } from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-menu-button",
  templateUrl: "./ignite-menu-button.component.html",
  styleUrls: ["./ignite-menu-button.component.scss"],
})
export class IgniteMenuButtonComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() hideOnShrink: boolean;
  @Input() disabled: boolean;
  @Input() link: string;
  @Input() badgeText: string;

  selected: boolean;
  inContext: boolean;

  constructor(private element: ElementRef<HTMLElement>) {}

  buttonEl(): HTMLElement {
    return this.element.nativeElement.firstChild as HTMLElement;
  }
}
