import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Utility } from "@ignite/ignite-common";

export function TurkishIdValidator(originalValue: () => string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (Utility.IsEmptyOrNull(control.value) || control.value === originalValue()) {
      return null;
    }
    const val = (control.value as string)?.trim();

    const regEx = new RegExp(/^[0-9]\d*$/);
    if (val.length !== 11 || !regEx.test(val)) {
      return { invalidId: true };
    }

    return null;
  };
}
