<div class="search-panel" [class.isHidden]="hideSearch">
  <div class="search-panel-row" [class.has-focus]="searchCriteria.searchText.length > 0" (scroll)="onScroll()">
    <table>
      <tr>
        <td>
          <div class="search-text-col">
            <div id="search_button" (click)="onSearch()">
              <i class="fal fa-search"></i>
            </div>
            <input
              #searchInput
              id="search_input"
              type="text"
              [placeholder]="placeholder"
              (blur)="onSearch()"
              (keydown.enter)="onSearch()"
              [(ngModel)]="searchCriteria.searchText"
            />
            <div
              *ngIf="searchCriteria.filterGroups.length > 0 && searchCriteria.searchText.length > 0"
              class="search-text-clear"
              (click)="clearText()"
            >
              <span i18n>CLEAR</span>
              <i class="fal fa-times-circle"></i>
            </div>
          </div>
        </td>
        <td>
          <div class="search-filters-col">
            <div class="search-filters">
              <ignite-search-filter
                *ngFor="let group of searchCriteria.filterGroups"
                [filterGroup]="group"
                (Close)="onFilterClose($event)"
                (Open)="onFilterOpen($event)"
                [attr.id]="group.id"
              ></ignite-search-filter>
            </div>
            <div class="search-filters-clear" (click)="clearAllFilters()">
              <span i18n>CLEAR ALL</span>
              <i class="fal fa-times-circle"></i>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="search-empty-results" *ngIf="showNoResults && !hideSearch">
  <ignite-card>
    <div card-title i18n>No results</div>
    <div card-content i18n>Try adjusting your search or removing filters.</div>
  </ignite-card>
  <div class="ignite-dialog-buttons-container">
    <div class="ignite-buttons">
      <div class="ignite-button-wrapper">
        <button id="button_clearall" mat-button class="ignite-button" color="primary" (click)="clearAllFilters()">Clear all</button>
      </div>
    </div>
  </div>
</div>
