/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Directive, ElementRef, AfterViewInit } from "@angular/core";
import { MatSlideToggle } from "@angular/material/slide-toggle";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[ignite-toggle]",
})
export class IgniteToggleDirective implements AfterViewInit {
  constructor(private el: ElementRef, private host: MatSlideToggle) {}

  ngAfterViewInit(): void {
    const $el = this.el.nativeElement;
    $el.classList.add("ignite-toggle");
    const $toggle = $el.getElementsByClassName("mat-mdc-slide-toggle-thumb");
    if ($toggle.length > 0) {
      const check = document.createElement("i");
      check.classList.add("fas");
      check.classList.add("fa-check");
      $toggle[0].appendChild(check);
    }
  }
}
