/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Directive, ElementRef, HostListener, AfterViewInit } from "@angular/core";
import { Utility } from "@ignite/ignite-common";
import { MatSelect } from "@angular/material/select";
import { MatOption } from "@angular/material/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[ignite-select]",
})
export class IgniteSelectDirective implements AfterViewInit {
  private isAllSelected = false;
  private selectAllCtrl = null;

  constructor(private el: ElementRef, private host: MatSelect) {
    this.host.disableOptionCentering = true;
    host.openedChange.subscribe((isOpenning: boolean) => {
      if (isOpenning) {
        this.host.panel.nativeElement.closest(".cdk-overlay-pane").onclick = ($event) => {
          if ($event.target.classList.contains("cdk-overlay-pane")) {
            this.host.close();
          }
        };

        if (this.host.multiple) {
          this.setupSelectAllCheckbox();
          this.host.selectionChange.emit();
        } else {
          this.scrollUp("mat-mdc-selected");
        }
      }
    });
  }

  private scrollUp(selector, direction = "") {
    const panel = this.host.panel?.nativeElement;
    if (!Utility.IsNull(panel)) {
      const selectedItem = panel.getElementsByClassName(selector);
      if (selectedItem.length > 0) {
        switch (direction) {
          case "":
          case "ArrowDown":
            this.host.panel.nativeElement.scrollTo({ top: selectedItem[0].offsetTop });
            break;
          case "PageDown":
          case "PageUp":
            this.host.panel.nativeElement.scrollTo({ top: selectedItem[0].offsetTop - 48 });
            break;
          case "ArrowUp":
            this.host.panel.nativeElement.scrollTo({ top: selectedItem[0].offsetTop - 96 });
            break;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    const arrowWrapper = this.el.nativeElement.querySelector(".mat-mdc-select-arrow-wrapper");
    if (!Utility.IsNull(arrowWrapper)) {
      while (arrowWrapper.firstChild) {
        arrowWrapper.removeChild(arrowWrapper.firstChild);
      }
      arrowWrapper.innerHTML = "<i class='fal fa-list'></i>";

      const optionsCount =
        this.host.options.length === 0
          ? 0
          : this.host.multiple || !Utility.IsNull(this.host.options.first.value)
          ? this.host.options.length
          : this.host.options.length - 1;
      if (optionsCount < 2) {
        const option: MatOption = this.host.options.find((x) => x.value !== null);
        if (!Utility.IsNull(option)) {
          option.select();
          this.host.disabled = true;
          if (!Utility.IsNull(this.host.ngControl) && !Utility.IsNull(this.host.ngControl.control)) {
            this.host.ngControl.control.markAsPristine();
          }
        }
      }

      if (this.host.multiple && optionsCount > 1) {
        this.host.selectionChange.subscribe(() => {
          const state = Utility.IsNull(this.host.options.find((option) => !option.selected));
          this.selectAll(state);
        });
      }
    }
  }

  @HostListener("window:resize")
  onResize(): void {
    if (!Utility.IsTouchDevice()) {
      this.host.close();
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyDown($event: KeyboardEvent): void {
    if ($event.key === "PageUp" || $event.key === "PageDown") {
      setTimeout(() => {
        this.scrollUp("mat-mdc-active", $event.key);
      }, 100);
    } else {
      this.scrollUp("mat-mdc-active", $event.key);
    }
  }

  private setupSelectAllCheckbox() {
    const element = this.el.nativeElement;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const optionsPanel = element.ownerDocument.getElementById(element.id + "-panel");
    if (optionsPanel !== null) {
      const selectAll = document.createElement("div");
      selectAll.classList.add("mat-mdc-option");
      selectAll.innerHTML =
        "<mat-mdc-pseudo-checkbox class='mat-mdc-pseudo-checkbox mat-mdc-option-pseudo-checkbox mat-mdc-pseudo-checkbox-full " +
        (this.isAllSelected ? "mat-mdc-pseudo-checkbox-checked" : "") +
        "'></mat-mdc-pseudo-checkbox><span>All</span>";
      this.selectAllCtrl = selectAll;
      optionsPanel.insertBefore(selectAll, optionsPanel.firstChild);

      selectAll.onclick = (ev) => {
        if (this.isAllSelected) {
          this.host.options.forEach((item: MatOption) => item.deselect());
          this.selectAll(false);
        } else {
          this.host.options.forEach((item: MatOption) => item.select());
          this.selectAll(true);
        }
        ev.stopPropagation();
      };
    }
  }

  private selectAll(selected) {
    const cb = this.selectAllCtrl?.firstChild as HTMLElement;
    if (!Utility.IsNull(cb)) {
      if (!selected) {
        cb.classList.remove("mat-mdc-pseudo-checkbox-checked");
        this.isAllSelected = false;
      } else {
        cb.classList.add("mat-mdc-pseudo-checkbox-checked");
        this.isAllSelected = true;
      }
    }
  }
}
