import { Component, Input, TemplateRef } from "@angular/core";

@Component({
  selector: "ignite-side-menu-item",
  templateUrl: "./ignite-side-menu-item.component.html",
  styleUrls: ["./ignite-side-menu-item.component.scss"],
})
export class IgniteSideMenuItemComponent {
  @Input() title? = "default";
  @Input() iconPath? = "";
  @Input() groupTitle? = "";
  @Input() isTabGroup? = false;
  @Input() lastOfGroup? = false;
  @Input() templateRef!: TemplateRef<any>;
}
