import { NativeDateAdapter } from "@angular/material/core";
import { Utility } from "@ignite/ignite-common";
import { IgniteDateFormatter } from "./date.formatter";

export class IgniteDateAdapter extends NativeDateAdapter {
  private static _locale = "en-US";

  static formatter = new IgniteDateFormatter();

  static get locale(): string {
    return IgniteDateAdapter._locale;
  }

  constructor(dateLocale: string) {
    super(dateLocale);
    IgniteDateAdapter._locale = dateLocale;
  }

  parse(value: string): Date | null {
    if (!value) {
      return null;
    }
    value = value.trim();
    const day = this.getDay(value);
    if (day < 0) {
      return this.invalid();
    }

    if (IgniteDateAdapter.locale === "en-GB" && value.indexOf("/") > 0) {
      const parts: string[] = value.split("/");
      if (parts.length > 2) {
        value = `${parts[1]}/${parts[0]}/${parts[2]}`;
      }
    }
    let date = new Date(Date.parse(value));
    if (isNaN(date.getTime())) {
      return this.invalid();
    }
    if (value.indexOf("-") > 0) {
      date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }

    if (date.getDate() !== day) {
      return this.invalid();
    }

    return date;
  }

  private getDay(v: string): number {
    const sep = v.indexOf("/") > 0 ? "/" : v.indexOf("-") > 0 ? "-" : " ";
    const parts = v.split(sep);
    if (parts.length < 2) {
      return -1;
    } else {
      return Number.parseInt(sep === "-" ? parts[2] : parts[0]);
    }
  }

  format(date: Date, displayFormat: string): string {
    if (Utility.IsEmptyOrNull(date)) {
      return null;
    }

    return IgniteDateAdapter.formatter.format(date, displayFormat) as string;
  }
}
