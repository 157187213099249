<div id="dialog_save" class="save-dialog">
  <div class="progress">
    <mat-progress-spinner
      ignite-progress-spinner
      [diameter]="70"
      color="primary"
      mode="determinate"
      [value]="progressValue"
    >
    </mat-progress-spinner>
  </div>
  <div mat-dialog-title id="title" color="primary">
    <span *ngIf="!isSaved" i18n>{{ savingMessage }}</span>
    <span *ngIf="isSaved" i18n>Changes have been<br />saved</span>
  </div>
  <div mat-dialog-content id="content">
    <p *ngIf="isSaved" i18n>{{ data.savedMessage }}</p>
  </div>
  <div mat-dialog-actions *ngIf="isSaved && !data?.hideOkButton">
    <div class="button-right">
      <button mat-raised-button type="button" id="button_ok" color="primary" (click)="dialogRef.close(true)" i18n="@@OK">OK</button>
    </div>
  </div>
</div>
