export enum IgniteActionType {
  Internal,
  External,
  File,
}

export class IgniteActionCardButton {
  constructor(n: string, t = IgniteActionType.Internal) {
    this.name = n;
    this.type = t;
  }
  name: string;
  type: IgniteActionType;
}
