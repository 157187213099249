import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService, Utility } from "@ignite/ignite-common";
import { IgniteBaseComponent } from "../ignite-base/ignite-base.component";
import { IgniteProfileMenuOption } from "../../models/ignite-profile-menu-option";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-toolbar",
  templateUrl: "./ignite-toolbar.component.html",
  styleUrls: ["./ignite-toolbar.component.scss"],
})
export class IgniteToolbarComponent extends IgniteBaseComponent {
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() profileOptions: IgniteProfileMenuOption[];

  constructor(private router: Router, private navService: NavigationService) {
    super();
  }

  goHome() {
    if (!Utility.IsNull(this.navService) && !Utility.IsNull(this.navService.canDeactivateComponent)) {
      this.navService.canDeactivateComponent.canDeactivate().subscribe({
        next: (proceed: boolean) => {
          if (proceed) {
            void this.router.navigate([""]);
          }
        },
      });
    } else {
      void this.router.navigate([""]);
    }
  }
}
