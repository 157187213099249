/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Directive, ElementRef, AfterViewInit, Input, OnChanges } from "@angular/core";
import { Utility } from "@ignite/ignite-common";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[ignite-progress-bar]",
})
export class IgniteProgressBarDirective implements AfterViewInit, OnChanges {
  @Input() color = "normal";

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const $el = this.el.nativeElement;
    $el.classList.add("ignite-progress-bar");
  }

  ngOnChanges(): void {
    const $el = this.el.nativeElement;
    $el.classList.remove("contrast-color");
    if (this.color === "contrast") {
      $el.classList.add("contrast-color");
    }
  }
}
