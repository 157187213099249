import { Directive, ElementRef, AfterViewInit } from "@angular/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { Utility } from "@ignite/ignite-common";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[ignite-date-picker]",
})
export class IgniteDatePickerDirective implements AfterViewInit {
  constructor(private el: ElementRef<Element>, private host: MatDatepicker<IgniteDatePickerDirective>) {
    host.openedStream.subscribe(() => {
      setTimeout(() => {
        this.doStyling(document.getElementById(this.host.id));
      });
    });
  }

  ngAfterViewInit(): void {
    const parent = this.el.nativeElement.parentElement;
    const inp = parent.getElementsByTagName("INPUT");
    if (!Utility.IsNull(inp)) {
      const icon = document.createElement("i");
      icon.classList.add("fal");
      icon.classList.add("fa-calendar-alt");
      icon.classList.add("ignite-date-picker-trigger");
      icon.onclick = ($event) => {
        this.host.open();
        $event.stopPropagation();
      };
      parent.insertBefore(icon, inp[0]);
    }
  }

  private doStyling(picker: HTMLElement) {
    if (!Utility.IsNull(picker) && !picker.classList.contains("ignite-date-picker")) {
      picker.classList.add("ignite-date-picker");
    } else {
      return;
    }

    let b = picker.getElementsByClassName("mat-calendar-previous-button");
    if (!Utility.IsNull(b)) {
      const previous = document.createElement("i");
      previous.classList.add("fal");
      previous.classList.add("fa-arrow-circle-left");
      b[0].appendChild(previous);
      b[0].classList.remove("mat-calendar-previous-button");
    }
    b = picker.getElementsByClassName("mat-calendar-next-button");
    if (!Utility.IsNull(b)) {
      const next = document.createElement("i");
      next.classList.add("fal");
      next.classList.add("fa-arrow-circle-right");
      b[0].appendChild(next);
      b[0].classList.remove("mat-calendar-next-button");
    }

    b = picker.getElementsByClassName("mat-calendar-arrow");
    if (!Utility.IsNull(b)) {
      let container = b[0];
      if (container.tagName.toUpperCase() === "SVG") {
        container = document.createElement("div") as HTMLElement;
        b[0].insertAdjacentElement("afterend", container);
        container.classList.add("mat-calendar-arrow");
        container.addEventListener("click", () => {
          if (container.classList.contains("mat-calendar-invert")) {
            container.classList.remove("mat-calendar-invert");
          } else {
            container.classList.add("mat-calendar-invert");
          }
        });
        b[0].remove();
      }
      const down = document.createElement("i");
      down.classList.add("far");
      down.classList.add("fa-chevron-down");
      container.appendChild(down);
      const up = document.createElement("i");
      up.classList.add("far");
      up.classList.add("fa-chevron-up");
      container.appendChild(up);
    }
  }
}
