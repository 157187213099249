/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AfterViewInit, Component, ContentChildren, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { Utility } from "@ignite/ignite-common";
import { IgniteBaseComponent } from "../ignite-base/ignite-base.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-sidebar",
  templateUrl: "./ignite-sidebar.component.html",
  styleUrls: ["./ignite-sidebar.component.scss"],
})
export class IgniteSidebarComponent extends IgniteBaseComponent implements AfterViewInit, OnDestroy {
  @ViewChild("buttonsContainer") buttonsContainer: ElementRef;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.updateClass(".ignite-main-content-container", "with-sidebar", true);
    this.updateClass(".ignite-main-content", "full-screen", true);

    const buttons = this.buttonsContainer.nativeElement.getElementsByTagName("ignite-sidebar-button");
    let c = 0;
    for (const b of buttons) {
      c++;
      if (c === 1) {
        const divider = document.createElement("div");
        divider.classList.add("sidebar-button-divider");
        b.insertAdjacentElement("beforebegin", divider);
      }
      const divider = document.createElement("div");
      divider.classList.add("sidebar-button-divider");
      b.insertAdjacentElement("afterEnd", divider);
    }
  }

  ngOnDestroy(): void {
    this.updateClass(".ignite-main-content-container", "with-sidebar", false);
    this.updateClass(".ignite-main-content", "full-screen", false);
  }

  private updateClass(className: string, subClass: string, activate: boolean): void {
    const el: HTMLElement = document.querySelector(className);
    if (!Utility.IsNull(el)) {
      if (activate) {
        el.classList.add(subClass);
      } else {
        el.classList.remove(subClass);
      }
    }
  }
}
