/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Directive, ElementRef, HostListener, AfterViewInit } from "@angular/core";
import { MatInput } from "@angular/material/input";
import { Utility } from "@ignite/ignite-common";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[ignite-password]",
})
export class IgnitePasswordDirective implements AfterViewInit {
  private passwordWrapper: HTMLElement;
  private eyeCtrl: HTMLElement;
  private timerId: any;
  private noStrengthCheck: boolean;

  constructor(private el: ElementRef, private host: MatInput) {
    const noStrengthCheck = this.el.nativeElement.attributes["no-strength-check"];
    this.noStrengthCheck = !Utility.IsEmptyOrNull(noStrengthCheck) && noStrengthCheck.value === "true";
  }

  ngAfterViewInit(): void {
    this.addHideShowControl();
    this.addStrengthControls();

    if (!Utility.IsNull(this.host.ngControl)) {
      this.host.ngControl.valueChanges.subscribe({
        next: (val) => {
          if (Utility.IsEmptyOrNull(val)) {
            this.validate();
          }
        },
      });
    }
  }

  @HostListener("keyup", ["$event"])
  onKeyUp(event: KeyboardEvent): void {
    this.validate();
    this.resetTimer();
  }

  @HostListener("focusout", ["$event"])
  onFocusout($event): void {
    const skipHiding = $event.relatedTarget !== null && $event.relatedTarget.classList.contains("show-hide-password");
    if (!skipHiding) {
      this.toggleShow(false);
    }
  }

  private resetTimer() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => this.toggleShow(false), 30000);
  }

  private addHideShowControl() {
    const el = this.el.nativeElement;
    el.style.width = "90%";
    this.eyeCtrl = document.createElement("div");
    this.eyeCtrl.setAttribute("tabindex", "-1");
    this.eyeCtrl.classList.add("show-hide-password");
    this.eyeCtrl.classList.add("hide");
    this.eyeCtrl.innerHTML = "<i class='fal fa-eye'></i><i class='fal fa-eye-slash'></i>";
    this.eyeCtrl.onclick = () => {
      this.toggleShow(this.eyeCtrl.classList.contains("hide"));
    };
    el.parentElement.appendChild(this.eyeCtrl);
  }

  private toggleShow(show: boolean) {
    this.resetTimer();
    if (show) {
      if (this.el.nativeElement.type === "password") {
        this.eyeCtrl.classList.remove("hide");
        this.eyeCtrl.classList.add("show");
        this.el.nativeElement.type = "text";
      }
    } else {
      if (this.el.nativeElement.type === "text") {
        this.eyeCtrl.classList.remove("show");
        this.eyeCtrl.classList.add("hide");
        this.el.nativeElement.type = "password";
      }
    }
  }

  private addStrengthControls() {
    if (this.noStrengthCheck) {
      return;
    }

    const wrapper = this.el.nativeElement.closest(".mat-mdc-form-field");
    if (!Utility.IsNull(wrapper)) {
      const strengthWrapper = document.createElement("div");
      strengthWrapper.classList.add("password-strength-wrapper");

      const checkStates = "<span><i class='far fa-check-circle'></i><i class='far fa-times-circle'></i></span>";

      const strengthContent =
        "<div class='password-strength'><span class='size-strength unchecked'>" +
        checkStates +
        "</span><span>8-40 characters</span></div>" +
        "<div class='password-strength'><span class='cap-strength unchecked'>" +
        checkStates +
        "</span><span>Uppercase</span></div>" +
        "<div class='password-strength'><span class='lower-strength unchecked'>" +
        checkStates +
        "</span><span>Lowercase</span></div>" +
        "<div class='password-strength'><span class='number-strength unchecked'>" +
        checkStates +
        "</span><span>Number</span></div>" +
        "<div class='password-strength'><span class='schar-strength unchecked'>" +
        checkStates +
        "</span><span>Special character</span></div>";

      strengthWrapper.innerHTML = `<div class='label for-desktop'>Must have:</div><div class='strengths'>${strengthContent}</div>`;
      this.passwordWrapper = strengthWrapper;

      wrapper.insertBefore(strengthWrapper, wrapper.firstChild);
    }
  }

  private validate() {
    if (this.noStrengthCheck) {
      return;
    }

    const ne = this.el.nativeElement;
    if (!ne.value) {
      this.setState("size-strength", "unchecked");
      this.setState("number-strength", "unchecked");
      this.setState("cap-strength", "unchecked");
      this.setState("lower-strength", "unchecked");
      this.setState("schar-strength", "unchecked");
      return;
    }

    if (ne.value.length < 8 || ne.value.length > 40) {
      this.setState("size-strength", "invalid");
    } else {
      this.setState("size-strength", "valid");
    }

    if (ne.value.length > 0) {
      this.setState("number-strength", new RegExp(/[0-9]/).test(ne.value) ? "valid" : "invalid");
      this.setState("cap-strength", new RegExp(/[A-Z]/).test(ne.value) ? "valid" : "invalid");
      this.setState("lower-strength", new RegExp(/[a-z]/).test(ne.value) ? "valid" : "invalid");
      this.setState("schar-strength", new RegExp(/[~`! @#$%^&*()_\-+={[\]}|\\:;"'<,>.?/]/).test(ne.value) ? "valid" : "invalid");
    }
  }

  private setState(strengthName: string, state: string) {
    const e = this.passwordWrapper.getElementsByClassName(strengthName)[0];
    e.classList.remove("unchecked");
    e.classList.remove("valid");
    e.classList.remove("invalid");
    e.classList.add(state);
  }
}
