<div [attr.id]="id" class="ignite-card {{ customClassName }} {{ type }}">
  <div class="ignite-card-title">
    <ng-content select="[card-title]"></ng-content>
  </div>
  <div class="ignite-card-subtitle">
    <ng-content select="[card-subtitle]"></ng-content>
  </div>
  <div class="ignite-card-content">
    <ng-content select="[card-content]"></ng-content>
  </div>
</div>
