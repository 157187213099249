<div class="ignite-panel-stepper-item-wrapper">
  <div class="ignite-panel-stepper-line" [class.completed]="completed">
    <i
      class="fal"
      [ngClass]="{ 'fa-check-circle': completed, 'fa-circle': !completed }"
      [matTooltip]="tooltip"
      matTooltipClass="ignite-tool-tip"
      (click)="changeCompleted()"
    ></i>
    <div *ngIf="isLastOfAll" class="ignite-panel-stepper-line-last" [style.background-color]="lastStepperBackgroundColor"></div>
  </div>
  <div class="ignite-panel-stepper-item-content" [class.last]="isLast">
    <ng-template #mainContent>
      <i
        class="fal fa-lg"
        [ngClass]="{ 'ignite-panel-stepper-square-checkbox': true, 'fa-check-square': completed, 'fa-square': !completed }"
        [matTooltip]="tooltip"
        matTooltipClass="ignite-tool-tip"
        (click)="changeCompleted()"
      ></i>
      <ng-content> </ng-content>
    </ng-template>
    <ng-container *ngTemplateOutlet="mainContent" />
  </div>
</div>
