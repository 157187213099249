import { Inject, Injectable, Input } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class IgnitePanelMenuOption {
  @Input() toggleCallback: (val: any) => Observable<any>;

  constructor(
    @Inject(String) selectLabel: string,
    @Inject(String) unselectLabel: string,
    @Inject(String) selectIcon: string = null,
    @Inject(String) unselectIcon: string = null,
    @Inject(String) selectedIndicator: string = null,
    @Inject(String) unselectedIndicator: string = null,
    @Inject(String) textColor: string = null
  ) {
    this.selectLabel = selectLabel;
    this.unselectLabel = unselectLabel;
    this.selectIcon = selectIcon;
    this.unselectIcon = unselectIcon;
    this.selectedIndicator = selectedIndicator;
    this.unselectedIndicator = unselectedIndicator;
    this.textColor = textColor;
  }

  selectLabel: string;
  unselectLabel: string;
  selectIcon: string;
  unselectIcon: string;
  selected: boolean;
  selectedIndicator: string;
  unselectedIndicator: string;
  textColor: string;
}
