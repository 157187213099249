<div class="search-filter" (click)="$event.stopPropagation()">
  <div class="search-filter-button" #toggle (click)="togglePopup($event)" [class.opened]="isOpened" [class.selected]="hasSelected()">
    {{ getButtonName() }}
  </div>
  <div #popup class="search-filter-popup" [class.opened]="isOpened">
    <div class="search-filter-item">
      <mat-checkbox *ngFor="let filter of filterGroup.filters" [(ngModel)]="filter.selected" labelPosition="after">{{ filter.displayName }}</mat-checkbox>
    </div>
    <div class="ignite-dialog-buttons-container">
        <div class="ignite-buttons">
          <a class="ignite-link" mat-flat-button (click)="clearFilter()">Clear</a>
          <div class="ignite-button-wrapper">
            <button id="button_save" mat-button class="ignite-button" color="primary" (click)="togglePopup($event)">Save</button>
          </div>
        </div>
      </div>
  </div>
</div>
