import { IgniteCountryCallingCode } from "./ignite-country-calling-code";
import { UIControlType } from "./ignite-control-type";
import { IgniteSelectOption } from "./ignite-select-option";
import { CustomErrorMessage, CustomValidator } from "../models/ignite-custom-validator";
import { CustomAsyncValidator } from "../models/ignite-custom-asyncvalidator";
import { Observable } from "rxjs";

export class IgniteGridFieldDef {
  name: string;
  label?: string;
  placeHolder?: string;
  controlType?: UIControlType;
  required?: boolean;
  disabled?: boolean;
  canAdd?: boolean;
  canEdit?: boolean;
  maxLength?: number;
  selectOptions?: IgniteSelectOption[];
  countryCallingCodes?: IgniteCountryCallingCode[] | Observable<IgniteCountryCallingCode[]>;
  customValidators?: CustomValidator[];
  customAsyncValidator?: CustomAsyncValidator;
  flatName?: string;
  customMessages?: CustomErrorMessage[];
}
