<div [attr.id]="id" class="ignite-action-card">
  <div class="ignite-action-card-image">
    <img id="image_action" alt="" [src]="imageUrl" />
  </div>
  <div class="ignite-action-card-title">
    <ng-content select="[action-card-title]"></ng-content>
  </div>
  <div class="ignite-action-card-subtitle">
    <ng-content select="[action-card-subtitle]"></ng-content>
  </div>
  <div class="ignite-action-card-content">
    <ng-content select="[action-card-content]"></ng-content>
  </div>
  <div class="ignite-buttons-container">
    <div class="ignite-button-wrapper" *ngFor="let act of actions">
      <div *ngIf="act.type === IgniteActionType.File">
        <button mat-button color="primary" class="ignite-button ignite-action-button" (click)="uploadFile.click()">{{ act.name }}</button>
        <input
          class="hidden"
          [id]="'ignite-action-card-action-' + act.name.replace(' ', '-')"
          [name]="'ignite-action-card-action-' + act.name.replace(' ', '-')"
          #uploadFile
          type="file"
          (change)="actionPerformed($event)"
          accept="image/*"
        />
      </div>

      <button
        *ngIf="act.type !== IgniteActionType.File"
        [id]="'ignite-action-card-action-' + act.name.replace(' ', '-')"
        mat-button
        color="primary"
        class="ignite-button ignite-action-button"
        (click)="actionPerformed($event)"
      >
        <i *ngIf="act.type === IgniteActionType.External" class="fal fa-external-link-square"></i>{{ act.name }}
      </button>
    </div>
  </div>
</div>
