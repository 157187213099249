<div class="free-content">
  <div class="free-content-menu-wrapper">
    <div class="free-content-menucombo-wrapper" mat-button [matMenuTriggerFor]="menu">
      <button class="side-menu-button">
        <img src="{{ activeComponent.iconPath }}" />
        {{ activeComponent.title }}
      </button>
      <mat-icon class="free-content-menucombo-icon">expand_more</mat-icon>
    </div>
    <mat-menu #menu="matMenu">
      <div class="side-menu-wrapper side-menu-wrapper-combo">
        <div
          mat-menu-item
          *ngFor="let tab of tabs; let isLast = last"
          [ngClass]="tab.isTabGroup ? 'side-menu-group' : 'side-menu-nogroup'"
          [class.last-item]="isLast"
          [disableRipple]="true"
        >
          <div *ngIf="tab.groupTitle.length > 0" class="side-menu-wrapper-title">{{ tab.groupTitle }}</div>
          <button [ngClass]="{ active: tab === activeComponent }" class="side-menu-button" (click)="comboItemClick(tab)">
            <img src="{{ tab.iconPath }}" />
            {{ tab.title }}
          </button>
        </div>
      </div>
    </mat-menu>

    <div class="side-menu-wrapper">
      <div *ngFor="let tab of tabs" [ngClass]="tab.isTabGroup ? 'side-menu-group' : 'side-menu-nogroup'">
        <div *ngIf="tab.groupTitle.length > 0" class="side-menu-wrapper-title">{{ tab.groupTitle }}</div>
        <button [ngClass]="{ active: tab === activeComponent }" class="side-menu-button" (click)="activateTab(tab)">
          <img src="{{ tab.iconPath }}" />
          {{ tab.title }}
        </button>
      </div>
    </div>
  </div>

  <div class="side-menu-content">
    <ng-template [ngTemplateOutlet]="activeComponent.templateRef"></ng-template>
  </div>
</div>
