<div *ngIf="initialized" [attr.id]="id" class="form-panel">
  <form [formGroup]="form">
    <div class="form-row">
      <div class="form-col" *ngFor="let fld of displayFields">
        <div class="mat-mdc-text-field-wrapper" *ngIf="fld.controlType === uiControlType.Checkbox">
          <mat-checkbox [attr.id]="id + '-' + fld.flatName" [formControlName]="fld.flatName">
            {{ fld.label }}
          </mat-checkbox>
        </div>

        <div class="mat-mdc-text-field-wrapper" *ngIf="fld.controlType === uiControlType.RadioButton">
          <mat-radio-group [attr.id]="id + '-' + fld.flatName" [formControlName]="fld.flatName">
            <mat-radio-button *ngFor="let option of fld.selectOptions" [value]="option.value">
              {{ option.text }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="fld.controlType === uiControlType.Autocomplete">
          <ignite-auto-complete
            [id]="id + '-' + fld.flatName"
            [label]="fld.label"
            [selectOptions]="fld.selectOptions"
            [placeholder]="fld.placeHolder"
            [frmCtrl]="form.get(fld.flatName)"
            [required]="fld.required"
          ></ignite-auto-complete>
        </div>

        <div *ngIf="fld.controlType === uiControlType.InputTelephone">
          <ignite-telephone-input
            [id]="id + '-' + fld.flatName"
            [label]="fld.label"
            [countryCallingCodes]="fld.countryCallingCodes"
            [placeholder]="fld.placeHolder"
            [formControlName]="fld.flatName"
            [required]="fld.required"
          ></ignite-telephone-input>
        </div>

        <div *ngIf="fld.controlType === uiControlType.Label">
          <ignite-label [id]="id + '-' + fld.flatName" [controlName]="fld.flatName"></ignite-label>
        </div>

        <mat-form-field
          *ngIf="
            fld.controlType !== uiControlType.Checkbox &&
            fld.controlType !== uiControlType.RadioButton &&
            fld.controlType !== uiControlType.Autocomplete &&
            fld.controlType !== uiControlType.InputTelephone &&
            fld.controlType !== uiControlType.Label &&
            fld.controlType !== uiControlType.Hidden
          "
          [attr.id]="id + '-' + fld.flatName"
          [hideRequiredMarker]="true"
          appearance="outline"
          [class.is-empty]="getFieldValue(fld.flatName) === ''"
        >
          <mat-label *ngIf="fld.label !== ''" floatLabel>{{ fld.label }}</mat-label>

          <div *ngIf="fld.controlType === uiControlType.InputText">
            <input matInput [formControlName]="fld.flatName" [placeholder]="fld.placeHolder" [attr.maxLength]="fld.maxLength" trim="blur" />
          </div>

          <div *ngIf="fld.controlType === uiControlType.InputPassword">
            <input
              ignite-password
              matInput
              type="password"
              [formControlName]="fld.flatName"
              [placeholder]="fld.placeHolder"
              [attr.maxLength]="fld.maxLength"
            />
          </div>

          <div *ngIf="fld.controlType === uiControlType.InputDate">
            <input
              matInput
              class="ignite-date-input"
              [matDatepicker]="picker"
              [formControlName]="fld.flatName"
              [placeholder]="fld.placeHolder"
            />
            <mat-datepicker ignite-date-picker #picker color="primary"></mat-datepicker>
          </div>

          <div *ngIf="fld.controlType === uiControlType.DropdownList">
            <mat-select ignite-select [formControlName]="fld.flatName" disableOptionCentering="true">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let option of fld.selectOptions" [value]="option.value">
                {{ option.text }}
              </mat-option>
            </mat-select>
          </div>

          <div *ngIf="fld.controlType === uiControlType.MultiSelect">
            <mat-select ignite-select [formControlName]="fld.flatName" multiple disableOptionCentering="true">
              <mat-option *ngFor="let option of filterOptions(fld)" [value]="option.value">
                {{ option.text }}
              </mat-option>
            </mat-select>
          </div>
          <mat-error *ngIf="form.get(fld.flatName).hasError('required')">{{ fld.label }} is required</mat-error>
          <mat-error class="ignite-custom-error" *ngFor="let cm of fld.customMessages">
            <span *ngIf="form.get(fld.flatName).hasError(cm.key)">{{ cm.message }}</span>
          </mat-error>
        </mat-form-field>
      </div> 
    </div>

    <div *ngIf="!readOnly" class="ignite-buttons-container">
      <div class="ignite-buttons">
        <div class="ignite-button-wrapper">
          <button
            id="ignite-grid-add-button"
            [disabled]="isSaving"
            mat-button
            color="primary"
            class="ignite-button"
            *ngIf="isAddMode"
            (click)="save()"
          >
            Add {{ name }}
          </button>
          <button
            id="ignite-grid-save-button"
            [disabled]="isSaving"
            mat-button
            color="primary"
            class="ignite-button"
            *ngIf="!isAddMode"
            (click)="save()"
          >
            {{ saveButtonLabel ? saveButtonLabel : "Save " + name }}
          </button>
        </div>
        <div class="ignite-button-wrapper">
          <button id="ignite-grid-cancel-button" mat-button class="ignite-button" type="button" (click)="cancel($event)">Cancel</button>
        </div>
      </div>
      <hr />
    </div>
  </form>
</div>
