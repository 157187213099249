import { Component, Input } from "@angular/core";
import { IgniteBaseComponent } from "../ignite-base/ignite-base.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-footer",
  templateUrl: "./ignite-footer.component.html",
  styleUrls: ["./ignite-footer.component.scss"],
})
export class IgniteFooterComponent extends IgniteBaseComponent {
  @Input() showCookiesLink = true;

  year = new Date().getUTCFullYear();

  constructor() {
    super();
  }
}
