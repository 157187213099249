/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Directive, ElementRef, AfterViewInit } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { Utility } from "@ignite/ignite-common";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[ignite-stepper]",
})
export class IgniteStepperDirective implements AfterViewInit {
  constructor(private el: ElementRef, private host: MatStepper) {}

  ngAfterViewInit(): void {
    const $el = this.el.nativeElement;
    $el.classList.add("ignite-stepper");

    const hasLabel = this.host.labelPosition === "bottom";
    if (hasLabel) {
      this.addHasLabelClass("mat-horizontal-stepper-header");
      this.addHasLabelClass("mat-horizontal-content-container");
      this.addHasLabelClass("mat-stepper-horizontal-line");
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const $iconContents = $el.getElementsByClassName("mat-step-icon-content");
    for (const iconContent of $iconContents) {
      const warning = document.createElement("span");
      warning.classList.add("step-error");
      warning.innerHTML = "!";
      iconContent.insertBefore(warning, iconContent.firstChild);

      const check = document.createElement("i");
      check.classList.add("step-check");
      check.classList.add("fal");
      check.classList.add("fa-check");
      iconContent.insertBefore(check, iconContent.firstChild);
    }

    this.host.selectionChange.subscribe({
      next: (s) => {
        const formGroup = s.selectedStep.stepControl;
        if (Utility.IsNull(formGroup)) {
          return;
        }
        if (formGroup.status === "INVALID") {
          const forms = this.el.nativeElement.querySelectorAll(".mat-horizontal-stepper-content > form");
          if (forms.length > s.selectedIndex) {
            const field = forms[s.selectedIndex].querySelector("input.ng-invalid");
            if (!Utility.IsNull(field)) {
              setTimeout(() => {
                field.focus();
              }, 300);
            }
          }
        }
      },
    });
  }

  private addHasLabelClass(className: string): void {
    const $el = this.el.nativeElement;
    const $items = $el.getElementsByClassName(className);
    for (const item of $items) {
      item.classList.add("has-label");
    }
  }
}
