import { AfterViewInit, Component, ContentChildren, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NavigationService, Utility } from "@ignite/ignite-common";
import { IgniteMenuButtonComponent } from "../../components/ignite-menu-button/ignite-menu-button.component";
import { IgniteBaseComponent } from "../ignite-base/ignite-base.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-menu",
  templateUrl: "./ignite-menu.component.html",
  styleUrls: ["./ignite-menu.component.scss"],
})
export class IgniteMenuComponent extends IgniteBaseComponent implements AfterViewInit, OnChanges {
  @ContentChildren(IgniteMenuButtonComponent)
  buttons: IgniteMenuButtonComponent[];
  @ViewChild("menuStrike") menuStrike: ElementRef<HTMLElement>;
  @Input() isHidden: false;

  constructor(private navService: NavigationService, private router: Router) {
    super();

    void router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.setMenuButton(event.url);
      }
    });
  }

  ngOnChanges(): void {
    this.adjustMainContainer();
  }

  ngAfterViewInit(): void {
    this.setMenuButton(this.router.url);
    this.adjustMainContainer();
  }

  private setMenuButton(url: string): void {
    let button = this.buttons.find((b) => url === "/" + b.link);
    if (!Utility.IsNull(button)) {
      if (!button.selected) {
        this.buttons.forEach((b) => (b.selected = false));
        button.selected = true;
      }
    } else {
      const selectedButton = this.buttons.find((b) => b.selected || b.inContext);
      if (!Utility.IsNull(selectedButton)) {
        selectedButton.selected = false;
        selectedButton.inContext = false;
      }
      button = this.buttons.find((b) => url.startsWith("/" + b.link));
      if (!Utility.IsNull(button)) {
        button.inContext = true;
      }
    }
    this.onResize();
  }

  private adjustMainContainer() {
    const mainContent: HTMLElement = document.querySelector(".ignite-main-content-container");
    if (!Utility.IsNull(mainContent)) {
      if (!this.isHidden) {
        if (!mainContent.classList.contains("menu-exists")) {
          mainContent.classList.add("menu-exists");
        }
      } else {
        mainContent.classList.remove("menu-exists");
      }
    }
  }

  onClickButton($event: Event): void {
    const buttonEl = $event.target as HTMLElement;
    const button = this.buttons.find((b) => b.label === buttonEl.getAttribute("label"));
    if (!Utility.IsNull(button) && !button.disabled) {
      if (!Utility.IsNull(this.navService) && !Utility.IsNull(this.navService.canDeactivateComponent)) {
        this.navService.canDeactivateComponent.canDeactivate().subscribe({
          next: (proceed: boolean) => {
            if (proceed) {
              this.selectOption(button);
            }
          },
        });
      } else {
        this.selectOption(button);
      }
    }
  }

  onMouseOver($event: MouseEvent): void {
    const buttonEl = $event.target as HTMLElement;
    if (buttonEl.classList.contains("ignite-menu-button")) {
      const button = this.buttons.find((b) => b.label === buttonEl.getAttribute("label"));
      if (!button.disabled) {
        this.positionStrike(buttonEl);
      }
    }
  }

  onMouseOut($event: MouseEvent): void {
    const buttonEl = $event.target as HTMLElement;
    if (buttonEl.classList.contains("ignite-menu-button")) {
      const button = this.buttons.find((b) => b.label === buttonEl.getAttribute("label"));
      if (!button.selected) {
        const selectedButton = this.buttons.find((b) => b.selected);
        this.positionStrike(Utility.IsNull(selectedButton) ? null : selectedButton.buttonEl());
      }
    }
  }

  onResize(): void {
    setTimeout(() => {
      const selectedButton = this.buttons.find((b) => b.selected);
      this.positionStrike(Utility.IsNull(selectedButton) ? null : selectedButton.buttonEl(), false);
    }, 200);
  }

  private selectOption(button: IgniteMenuButtonComponent) {
    this.buttons.map((b) => {
      b.selected = false;
      b.inContext = false;
    });
    button.selected = true;
    this.positionStrike(button.buttonEl());
    if (button.link) {
      void this.router.navigate([button.link]);
    }
  }

  private positionStrike(menuItem: HTMLElement, useTransition = true, retryCount = 0) {
    const strike = this.menuStrike.nativeElement;
    strike.style.transition = useTransition ? "all ease-in-out 0.3s" : "none";
    if (menuItem == null) {
      strike.style.visibility = "hidden";
    } else {
      if (menuItem.offsetLeft === 0 && menuItem.offsetWidth === 0) {
        if (retryCount < 2) {
          setTimeout(() => this.positionStrike(menuItem, useTransition, ++retryCount), 300);
        }
      } else {
        const left = `${menuItem.offsetLeft}px`;
        const width = `${menuItem.offsetWidth}px`;
        strike.style.visibility = "visible";
        strike.style.left = left;
        strike.style.width = width;
      }
    }
  }
}
