import { SortDirection } from "@angular/material/sort";

export class IgniteGridColumnDef {
  header: string;
  field: string | string[];
  widthPercent?: string = null;
  alignment?: string = null;
  hiddenInMobile?: boolean;
  sortable?: boolean;
  sortDirection?: SortDirection;
  sortField?: string;
  isPrimarySort?: boolean;
  customComponent?: any;
  shownWhenSelected?: boolean;
  mapValueFunction?: (val: any) => string;
}
