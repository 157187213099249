import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Utility } from "@ignite/ignite-common";

export function PasswordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (Utility.IsEmptyOrNull(control.value)) {
      return null;
    }

    const pwd = control.value as string;

    const results = {};
    if (Utility.IsEmptyOrNull(pwd) || pwd.length < 8 || pwd.length > 40) {
      results["size"] = pwd.length;
    }

    if (!Utility.IsEmptyOrNull(pwd)) {
      if (!new RegExp(/[a-z]/).test(pwd)) {
        results["lower"] = pwd;
      }
      if (!new RegExp(/[0-9]/).test(pwd)) {
        results["number"] = pwd;
      }
      if (!new RegExp(/[A-Z]/).test(pwd)) {
        results["capital"] = pwd;
      }
      if (!new RegExp(/[~`! @#$%^&*()_\-+={[\]}|\\:;"'<,>.?/]/).test(pwd)) {
        results["schar"] = pwd;
      }
    }
    return results;
  };
}
