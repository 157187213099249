<div #telephoneInputDiv [attr.id]="id" class="ignite-telephone-input">
  <mat-form-field
    class="ignite-telephone-country-calling-code"
    [hideRequiredMarker]="true"
    appearance="outline"
    [class.is-empty]="isCountryCallingCodeEmpty()"    
  >
    <mat-label floatLabel i18n>Country code</mat-label>
    <input
      matInput
      [id]="countryCodeId"
      [formControl]="countryCode"
      [required]="required"
      [readonly]="readonly"
      [matAutocomplete]="auto"
      (keypress)="onCountryCodeKeyPress($event)"
      (blur)="onBlur()"
    />
    <mat-error *ngIf="countryCode.hasError('required')" i18n="@@Required">Country code is required</mat-error>
    <mat-error *ngIf="countryCode.hasError('unmatchedItem')" i18n>Country code is invalid</mat-error>
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption="true"
      [panelWidth]="countryCodeWidth$ | async"
      [displayWith]="showCountryCallingCode"
    >
      <mat-option *ngFor="let c of filteredCountryCallingCodes$ | async" [value]="c">
        <div>{{ c.name }}&nbsp;+{{ c.callingCode }}</div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field
    class="ignite-telephone-number"
    [hideRequiredMarker]="true"
    appearance="outline"
    [class.is-empty]="isTelephoneNumberEmpty()"
  >
    <mat-label floatLabel i18n>{{ label }}</mat-label>
    <input
      matInput
      type="tel"
      [id]="telephoneId"
      [formControl]="telephoneNumber"
      [required]="required"
      [readonly]="readonly"
      [placeholder]="placeholder"
      maxlength="20"
      (keypress)="onTelephoneKeyPress($event)"
      (blur)="formatTelephoneNumber(); onBlur()"
    />
    <mat-error *ngIf="telephoneNumber.hasError('required')" i18n="@@Required">Telephone number is required</mat-error>
    <mat-error *ngIf="telephoneNumber.hasError('invalidPhoneNumber')" i18n="@@InvalidPhoneNumber">Invalid telephone number</mat-error>
  </mat-form-field>
</div>
