<mat-horizontal-stepper ignite-stepper [@.disabled]="true" labelPosition="bottom" #stepper>
  <mat-step [stepControl]="headOfficeForm">
    <ng-template matStepLabel>Goal</ng-template>
    <form [formGroup]="headOfficeForm">
      <div class="grid-panel">
        <div class="grid-row-details">
          <div class="form-row">
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-name"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('name').value === ''"
              >
                <mat-label floatLabel i18n>Location name</mat-label>
                <input formControlName="name" matInput placeholder="Head office name" maxlength="42" trim="blur" />
                <mat-error id="error_name_required" *ngIf="headOfficeForm.get('name').hasError('required')" i18n
                  >Location name is required</mat-error
                >
                <mat-error id="error_name_invalid" *ngIf="headOfficeForm.get('name').hasError('pattern')" i18n
                  >Invalid characters</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-phone"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('phone').value === ''"
              >
                <mat-label floatLabel i18n>Phone number</mat-label>
                <input formControlName="phone" matInput maxlength="32" trim="blur" />
                <mat-error id="error_phone_required" *ngIf="headOfficeForm.get('phone').hasError('required')" i18n
                  >Phone number is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-streetAddress1"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('streetAddress1').value === ''"
              >
                <mat-label floatLabel i18n>Address 1</mat-label>
                <input formControlName="streetAddress1" matInput maxlength="42" trim="blur" />
                <mat-error id="error_address_required" *ngIf="headOfficeForm.get('streetAddress1').hasError('required')" i18n
                  >Address 1 is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-streetAddress2"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('streetAddress2').value === ''"
              >
                <mat-label floatLabel i18n>Address 2 (optional)</mat-label>
                <input formControlName="streetAddress2" matInput maxlength="42" trim="blur" />
              </mat-form-field>
            </div>
            <div class="form-col">
              <ignite-auto-complete
                id="ignite-locations-grid-country"
                label="Country"
                [selectOptions]="countries"
                [frmCtrl]="headOfficeForm.get('country')"
                [required]="true"
              ></ignite-auto-complete>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-city"
                appearance="outline"
                [hideRequiredMarker]="true"
                required
                [class.is-empty]="headOfficeForm.get('city').value === ''"
              >
                <mat-label floatLabel i18n>City</mat-label>
                <input formControlName="city" matInput maxlength="32" trim="blur" />
                <mat-error id="error_city_required" *ngIf="headOfficeForm.get('city').hasError('required')" i18n
                  >City is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-stateProvince"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('stateProvince').value === ''"
              >
                <mat-label floatLabel i18n>State/Province</mat-label>
                <input formControlName="stateProvince" matInput maxlength="32" trim="blur" />
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-postalCode"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('postalCode').value === ''"
              >
                <mat-label floatLabel i18n>Zip/Postal code</mat-label>
                <input formControlName="postalCode" matInput maxlength="16" trim="blur" />
                <mat-error id="error_name_invalid" *ngIf="headOfficeForm.get('postalCode').hasError('pattern')" i18n
                  >Invalid characters</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="ignite-buttons-container">
        <div class="ignite-buttons">
          <div class="ignite-button-wrapper">
            <button mat-button class="ignite-button hover" color="primary" matStepperNext>Next</button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <!-- #docregion label -->
  <mat-step [stepControl]="userProfileForm">
    <ng-template matStepLabel>Focus</ng-template>
    <form [formGroup]="userProfileForm">
      <div class="single-col-panel">
        <div class="form-col">
          <mat-form-field
            id="field_first_name"
            appearance="outline"
            [hideRequiredMarker]="true"
            [class.is-empty]="userProfileForm.get('firstName').value === ''"
          >
            <input matInput formControlName="firstName" required maxlength="42" trim="blur" />
            <mat-label i18n>First name</mat-label>
            <mat-error id="first_name_error_required" *ngIf="userProfileForm.get('firstName').hasError('required')" i18n
              >First name is required</mat-error
            >
            <mat-error id="first_name_error_pattern" *ngIf="userProfileForm.get('firstName').hasError('invalidChars')" i18n
              >First name contains invalid characters</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-col">
          <mat-form-field
            id="field_last_name"
            appearance="outline"
            [hideRequiredMarker]="true"
            [class.is-empty]="userProfileForm.get('lastName').value === ''"
          >
            <input matInput formControlName="lastName" required maxlength="42" trim="blur" />
            <mat-label i18n>Last name</mat-label>
            <mat-error id="last_name_error_required" *ngIf="userProfileForm.get('lastName').hasError('required')" i18n
              >Last name is required</mat-error
            >
            <mat-error id="last_name_error_pattern" *ngIf="userProfileForm.get('lastName').hasError('invalidChars')" i18n
              >Last name contains invalid characters</mat-error
            >
          </mat-form-field>
        </div>

        <div class="form-col">
          <mat-form-field
            id="field_email"
            appearance="outline"
            [hideRequiredMarker]="true"
            [class.is-empty]="userProfileForm.get('email').value === ''"
          >
            <input id="tt-email" matInput formControlName="email" required maxlength="255" trim="blur" />
            <mat-label i18n>Email address</mat-label>
            <mat-error id="email_error_required" *ngIf="userProfileForm.get('email').hasError('required')" i18n
              >Email address is required</mat-error
            >
            <mat-error id="email_error_invalid_email" *ngIf="userProfileForm.get('email').hasError('email')" i18n
              >Invalid email address</mat-error
            >
            <mat-error id="email_error_username_taken" *ngIf="userProfileForm.get('email').hasError('usernameTaken')" i18n
              >Email already in use</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-col">
          <ignite-telephone-input
            id="field_primary_phone"
            formControlName="primaryPhone"
            label="Telephone (mobile)"
            required="true"
            [countryCallingCodes]="countryCallingCodes"
          ></ignite-telephone-input>
        </div>
      </div>

      <div class="ignite-buttons-container">
        <div class="ignite-buttons">
          <div class="ignite-button-wrapper">
            <button mat-button type="button" class="ignite-button" color="primary">Save</button>
          </div>
          <div class="ignite-button-wrapper">
            <button class="ignite-button" type="button" mat-button>Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>

<h1>Vertical Stepper</h1>

<mat-stepper ignite-stepper orientation="vertical" #verticalstepper>
  <mat-step [stepControl]="headOfficeForm">
    <form [formGroup]="headOfficeForm">
      <div class="grid-panel">
        <div class="grid-row-details">
          <div class="form-row">
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-name"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('name').value === ''"
              >
                <mat-label floatLabel i18n>Location name</mat-label>
                <input formControlName="name" matInput placeholder="Head office name" maxlength="42" trim="blur" />
                <mat-error id="error_name_required" *ngIf="headOfficeForm.get('name').hasError('required')" i18n
                  >Location name is required</mat-error
                >
                <mat-error id="error_name_invalid" *ngIf="headOfficeForm.get('name').hasError('pattern')" i18n
                  >Invalid characters</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-phone"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('phone').value === ''"
              >
                <mat-label floatLabel i18n>Phone number</mat-label>
                <input formControlName="phone" matInput maxlength="32" trim="blur" />
                <mat-error id="error_phone_required" *ngIf="headOfficeForm.get('phone').hasError('required')" i18n
                  >Phone number is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-streetAddress1"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('streetAddress1').value === ''"
              >
                <mat-label floatLabel i18n>Address 1</mat-label>
                <input formControlName="streetAddress1" matInput maxlength="42" trim="blur" />
                <mat-error id="error_address_required" *ngIf="headOfficeForm.get('streetAddress1').hasError('required')" i18n
                  >Address 1 is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-streetAddress2"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('streetAddress2').value === ''"
              >
                <mat-label floatLabel i18n>Address 2 (optional)</mat-label>
                <input formControlName="streetAddress2" matInput maxlength="42" trim="blur" />
              </mat-form-field>
            </div>
            <div class="form-col">
              <ignite-auto-complete
                id="ignite-locations-grid-country"
                label="Country"
                [selectOptions]="countries"
                [frmCtrl]="headOfficeForm.get('country')"
                [required]="true"
              ></ignite-auto-complete>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-city"
                appearance="outline"
                [hideRequiredMarker]="true"
                required
                [class.is-empty]="headOfficeForm.get('city').value === ''"
              >
                <mat-label floatLabel i18n>City</mat-label>
                <input formControlName="city" matInput maxlength="32" trim="blur" />
                <mat-error id="error_city_required" *ngIf="headOfficeForm.get('city').hasError('required')" i18n
                  >City is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-stateProvince"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('stateProvince').value === ''"
              >
                <mat-label floatLabel i18n>State/Province</mat-label>
                <input formControlName="stateProvince" matInput maxlength="32" trim="blur" />
              </mat-form-field>
            </div>
            <div class="form-col">
              <mat-form-field
                id="ignite-locations-grid-postalCode"
                appearance="outline"
                [hideRequiredMarker]="true"
                [class.is-empty]="headOfficeForm.get('postalCode').value === ''"
              >
                <mat-label floatLabel i18n>Zip/Postal code</mat-label>
                <input formControlName="postalCode" matInput maxlength="16" trim="blur" />
                <mat-error id="error_name_invalid" *ngIf="headOfficeForm.get('postalCode').hasError('pattern')" i18n
                  >Invalid characters</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="ignite-buttons-container">
        <div class="ignite-buttons">
          <div class="ignite-button-wrapper">
            <button mat-button class="ignite-button hover" color="primary" matStepperNext>Next</button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <!-- #docregion label -->
  <mat-step [stepControl]="userProfileForm">
    <form [formGroup]="userProfileForm">
      <div class="single-col-panel">
        <div class="form-col">
          <mat-form-field
            id="field_first_name"
            appearance="outline"
            [hideRequiredMarker]="true"
            [class.is-empty]="userProfileForm.get('firstName').value === ''"
          >
            <input matInput formControlName="firstName" required maxlength="42" trim="blur" />
            <mat-label i18n>First name</mat-label>
            <mat-error id="first_name_error_required" *ngIf="userProfileForm.get('firstName').hasError('required')" i18n
              >First name is required</mat-error
            >
            <mat-error id="first_name_error_pattern" *ngIf="userProfileForm.get('firstName').hasError('invalidChars')" i18n
              >First name contains invalid characters</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-col">
          <mat-form-field
            id="field_last_name"
            appearance="outline"
            [hideRequiredMarker]="true"
            [class.is-empty]="userProfileForm.get('lastName').value === ''"
          >
            <input matInput formControlName="lastName" required maxlength="42" trim="blur" />
            <mat-label i18n>Last name</mat-label>
            <mat-error id="last_name_error_required" *ngIf="userProfileForm.get('lastName').hasError('required')" i18n
              >Last name is required</mat-error
            >
            <mat-error id="last_name_error_pattern" *ngIf="userProfileForm.get('lastName').hasError('invalidChars')" i18n
              >Last name contains invalid characters</mat-error
            >
          </mat-form-field>
        </div>

        <div class="form-col">
          <mat-form-field
            id="field_email"
            appearance="outline"
            [hideRequiredMarker]="true"
            [class.is-empty]="userProfileForm.get('email').value === ''"
          >
            <input id="tt-email" matInput formControlName="email" required maxlength="255" trim="blur" />
            <mat-label i18n>Email address</mat-label>
            <mat-error id="email_error_required" *ngIf="userProfileForm.get('email').hasError('required')" i18n
              >Email address is required</mat-error
            >
            <mat-error id="email_error_invalid_email" *ngIf="userProfileForm.get('email').hasError('email')" i18n
              >Invalid email address</mat-error
            >
            <mat-error id="email_error_username_taken" *ngIf="userProfileForm.get('email').hasError('usernameTaken')" i18n
              >Email already in use</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-col">
          <ignite-telephone-input
            id="field_primary_phone"
            formControlName="primaryPhone"
            label="Telephone (mobile)"
            required="true"
            [countryCallingCodes]="countryCallingCodes"
          ></ignite-telephone-input>
        </div>
      </div>

      <div class="ignite-buttons-container">
        <div class="ignite-buttons">
          <div class="ignite-button-wrapper">
            <button mat-button type="button" class="ignite-button" color="primary">Save</button>
          </div>
          <div class="ignite-button-wrapper">
            <button class="ignite-button" type="button" mat-button>Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
</mat-stepper>

<h1>Expansible pannel with Stepper</h1>

<ignite-panel-stepper [panelTitle]="'Familiarize yourself with PTE Academic'" [panelNumber]="'01'">
  <ignite-panel-stepper-item itemId="item1-1" [completed]="true" (onCompletedChanged)="onCompleted($event)">
    <h3>Stepper item 1</h3>
    <p>Item 1 description</p>
  </ignite-panel-stepper-item>

  <ignite-panel-stepper-item itemId="item1-2" [completed]="true" (onCompletedChanged)="onCompleted($event)" [isLast]="true">
    <h3>Stepper item 2</h3>
    <p>Item 2 description</p>
  </ignite-panel-stepper-item>
</ignite-panel-stepper>

<ignite-panel-stepper [panelTitle]="'Assess your current level of English'" [panelNumber]="'02'">
  <ignite-panel-stepper-item itemId="item2-1" [completed]="false" (onCompletedChanged)="onCompleted($event)">
    <h3>Stepper item 1</h3>
    <p>Item 1 description</p>
  </ignite-panel-stepper-item>

  <ignite-panel-stepper-item itemId="item2-2" [completed]="false" (onCompletedChanged)="onCompleted($event)">
    <h3>Stepper item 2</h3>
    <p>Item 2 description</p>
  </ignite-panel-stepper-item>

  <ignite-panel-stepper-item itemId="item2-3" [completed]="false" [isLastOfAll]="true" (onCompletedChanged)="onCompleted($event)" lastStepperBackgroundColor="white">
    <h3>Stepper item 3</h3>
    <p>Item 3 description</p>
  </ignite-panel-stepper-item>
</ignite-panel-stepper>
