import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Utility } from "@ignite/ignite-common";

export function MustSelectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if ((control.touched || !control.pristine) && Utility.IsEmptyOrNull(control.value)) {
      return { notSelected: true };
    }
    return null;
  };
}
