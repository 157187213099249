<div class="ignite-profile-menu-container">
  <div class="ignite-profile-menu" (click)="toggleMenu($event)">
    <i class="fal fa-bars"></i>
    <span class="ignite-profile-user-initials">{{ getUserInitials() }}</span>
  </div>
  <div class="ignite-profile-menu-popup" [class.opened]="isOpened">
    <div
      [id]="option.route + '-profile-item' | replace: '[/]':''"
      class="ignite-profile-menu-option"
      *ngFor="let option of menuOptions"
      (click)="onOptionClick($event, option)"
    >
      <i *ngIf="option.icon !== null" class="fal" [ngClass]="option.icon"></i>
      <div [innerHTML]="option.label | safeHtml"></div>
    </div>
  </div>
</div>
