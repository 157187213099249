import { IgniteSnackbarType } from "./ignite-snackbar-type";

export class IgniteSnackbarData {
  constructor(message: string, type: IgniteSnackbarType, action: string = null) {
    this.message = message;
    this.type = type;
    this.action = action;
  }
  message: string;
  type: IgniteSnackbarType;
  action: string;
}
