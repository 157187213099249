<ignite-card id="team-dashboard-header">
  <div card-title i18n>You're all set. Control your teams from here.</div>
  <div card-content i18n>Add team members, create locations and find helpful hints.</div>
</ignite-card>
<div>
  <mat-label class="ignite-label">Tabs style</mat-label>
  <mat-radio-group class="horizontal-layout" [(ngModel)]="tabStyleSelected">
    <mat-radio-button *ngFor="let s of tabStyles" [value]="s.value">
      {{ s.viewValue }}
    </mat-radio-button>
  </mat-radio-group>
</div>
<mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="tabStyleSelected === '1'">Form</span>
    </ng-template>
    <br />
    <ignite-panel [panelTitle]="['Australian Agency', 'A12']" [panelMenuOptions]="panelMenuOptions">
      <form [formGroup]="form">
        <mat-form-field [hideRequiredMarker]="true" appearance="outline">
          <mat-label floatLabel>Outline form field with error and icon</mat-label>
          <input matInput placeholder="pat@example.com" [formControl]="email" required />
          <mat-error *ngIf="email.invalid">{{ getErrorMessage() }}</mat-error>
        </mat-form-field>

        <mat-form-field [hideRequiredMarker]="true" appearance="outline">
          <mat-label floatLabel>Notes (text-area)</mat-label>
          <textarea
            matInput
            placeholder="Enter your notes here..."
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            formControlName="testInput"
            required
          ></textarea>
          <mat-error *ngIf="form.get('testInput').hasError('required')">Notes are required</mat-error>
        </mat-form-field>

        <div>
          <ignite-date label="Date of birth" formControlName="dob"></ignite-date>
        </div>

        <mat-form-field [hideRequiredMarker]="true" appearance="outline">
          <mat-label floatLabel>Regular date</mat-label>
          <input matInput class="ignite-date-input" formControlName="regularDate" [matDatepicker]="picker" placeholder="Enter a date" />
          <mat-datepicker ignite-date-picker #picker color="primary"></mat-datepicker>
          <mat-error *ngIf="form.get('regularDate').hasError('required') && !form.get('regularDate').hasError('matDatepickerParse')"
            >Date is required</mat-error
          >
          <mat-error *ngIf="form.get('regularDate').hasError('matDatepickerParse')" i18n>Invalid date</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Basic Select</mat-label>
          <mat-select ignite-select placeholder="pick one">
            <mat-option *ngFor="let food of foods" [value]="food.value">
              {{ food.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Basic Select with just one option</mat-label>
          <mat-select ignite-select placeholder="pick one">
            <mat-option *ngFor="let food of buffet" [value]="food.value">
              {{ food.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Multi Select</mat-label>
          <mat-select ignite-select multiple placeholder="pick one or more">
            <mat-option *ngFor="let food of foods" [value]="food.value">
              {{ food.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div>
          <ignite-auto-complete
            id="id - autocomplete"
            label="Autocomplete for form"
            [selectOptions]="options"
            [frmCtrl]="myControl"
            placeholder="Pick one"
          ></ignite-auto-complete>
        </div>
        <div>
          <ignite-telephone-input
            label="Telephone number"
            placeholder="Telephone input for test takers"
            required="true"
            [countryCallingCodes]="countryCallingCodes"
            formControlName="phone"
          ></ignite-telephone-input>
        </div>

        <ignite-alert>
          <div alert-title>Match found</div>
          <div alert-content>
            This email address is associated with an existing Test Taker account but the chosen date of birth does not match. Please choose
            a different email address or enter in a different date of birth.
          </div>
        </ignite-alert>

        <mat-checkbox>Is account active</mat-checkbox>
        <br />

        <mat-label class="ignite-label">Radio buttons - horizontal layout on desktop</mat-label>
        <mat-radio-group class="horizontal-layout" formControlName="radiob">
          <mat-radio-button *ngFor="let food of foods" [value]="food.value">
            {{ food.viewValue }}
          </mat-radio-button>
          <mat-error *ngIf="form.get('radiob').hasError('notSelected')" i18n> Required</mat-error>
         </mat-radio-group>

        <mat-label class="ignite-label">Radio buttons - vertical layout</mat-label>
        <mat-radio-group>
          <mat-radio-button *ngFor="let food of foods" [value]="food.value">
            {{ food.viewValue }}
          </mat-radio-button>
        </mat-radio-group>
        <br />
        <br />

        <div class="ignite-buttons-container">
          <div class="ignite-buttons">
            <div class="ignite-button-wrapper">
              <button mat-button class="ignite-button" color="primary" (click)="onAdd()">Add New Record</button>
            </div>
            <div class="ignite-button-wrapper">
              <button class="ignite-button" mat-button>Cancel</button>
            </div>
          </div>
          <hr />
        </div>
      </form>
    </ignite-panel>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="tabStyleSelected === '1'">Controls</span>
    </ng-template>
    <span matTooltip="Resend welcome email">Tooltip</span>
    <br />
    <mat-form-field [hideRequiredMarker]="true" appearance="outline">
      <input ignite-password matInput type="password" placeholder="Create a new password" />
    </mat-form-field>
    <br />
    <mat-slide-toggle ignite-toggle>All email communications</mat-slide-toggle>
    <br /><br />
    <div>Progress bar&nbsp;<i class="fal fa-question-circle" (click)="openTemplateSheetMenu()"></i></div>
    <br />
    <mat-progress-bar ignite-progress-bar mode="determinate" value="40"></mat-progress-bar>
    <br />
    <div>Progress spinner&nbsp;<i class="fal fa-question-circle" (click)="openTemplateSheetMenu(2)"></i></div>
    <br />
    <mat-progress-spinner ignite-progress-spinner class="example-margin" color="primary" mode="determinate" [value]="progressValue">
    </mat-progress-spinner>
    <br />
    <div class="ignite-buttons-container" style="width: 300px">
      <button class="ignite-button" (click)="showSnackbar('success')" mat-button>Show success snackbar</button>
      <button class="ignite-button" (click)="showSnackbar('alert')" mat-button>Show alert snackbar</button>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="tabStyleSelected === '1'">Grid</span>
    </ng-template>
    <ignite-search placeholder="Search by name or PTE ID" (search)="onSearch($event)" [searchCriteria]="searchCriteria"></ignite-search>
    <div class="grid-container">
      <ignite-panel-grid
        id="ignite-locations-grid"
        name="location"
        [dataSource]="locationDS"
        [columnsDef]="gridColumns"
        [fieldsDef]="gridFields"
        (selectEvent)="paginator.onGridSelect($event)"
        (pageEvent)="onPaging($event)"
        (sortEvent)="onSort($event)"
      ></ignite-panel-grid>
      <br />
      <ignite-paginator #paginator (page)="onPaging($event)" [dataSource]="locationDS"></ignite-paginator>
    </div>
  </mat-tab>
  <mat-tab label="Accordion">
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="tabStyleSelected === '1'">Accordion</span>
    </ng-template>
    <div class="grid-container flex-height-row">
      <ignite-panel-grid
        id="ignite-accordion-grid"
        name="accordion"
        [dataSource]="locationDS"
        [columnsDef]="gridColumns"
        [fieldsDef]="gridFields"
        [accordion]="true"
        [readOnly]="true"
      ></ignite-panel-grid>
    </div>
  </mat-tab>
  <mat-tab label="Custom Accordion">
    <ng-template mat-tab-label>
      <span class="label-text" [class.menu-style]="tabStyleSelected === '1'"> Custom Accordion</span>
    </ng-template>
    <div class="grid-container flex-height-row" style="max-width: 900px; margin-left: auto; margin-right: auto">
      <ignite-panel-grid
        #igniteCustomAccordionGrid
        name="customAccordion"
        [dataSource]="customDS"
        [columnsDef]="customColumns"
        [fieldsDef]="customFields"
        [accordion]="true"
        [readOnly]="true"
        [showHeaderWhenItemSelected]="true"
        [accordionForm]="customContent"
        (selectEvent)="onCustomSelect($event)"
      >
        <div #customContent *ngIf="true">
          <div *ngIf="igniteCustomAccordionGrid.selectedRow !== null" style="margin-top: -30px; margin-bottom: 30px">
            <table class="customTable">
              <tr *ngFor="let item of igniteCustomAccordionGrid.selectedRow.details">
                <td>{{ item.description }}</td>
                <td style="text-align: right">
                  <span *ngIf="item.kind == 'subtotal' || item.kind == 'total'">Total: </span>{{ getPrice(item.price) }}
                  <div *ngIf="item.kind == 'subtotal'">&nbsp;</div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td style="text-align: right"><a target="_blank" href="https://apple.com">View order summary</a></td>
              </tr>
            </table>
          </div>
        </div>
      </ignite-panel-grid>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet>
  <div class="bottom-sheet">
    <img src="/assets/images/Goal.png" />
    <div class="title">Why do we need this information?</div>
    <ul>
      <li>To make sure we understand your study goals</li>
      <li>To calculate where you at now and the score goals you want to reach</li>
    </ul>
  </div>
</ng-template>

<ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet2>
  <div class="bottom-sheet">
    <img src="/assets/images/Focus.png" />
    <div>Do you feel you need to improve most in speaking, reading, writing or listening?</div>
  </div>
</ng-template>
<br /><br />
