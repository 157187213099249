import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { Utility } from "@ignite/ignite-common";
import { IgniteSnackbarData } from "../../models/ignite-snackbar-data";
import { IgniteSnackbarType } from "../../models/ignite-snackbar-type";

@Component({
  selector: "ignite-snackbar",
  templateUrl: "./ignite-snackbar.component.html",
  styleUrls: ["./ignite-snackbar.component.scss"],
})
export class IgniteSnackbarComponent implements OnInit {
  static nextId = 0;
  @Input() id: string;
  message: string;
  action: string;
  snackbarType: IgniteSnackbarType;
  igniteSnackbarType = IgniteSnackbarType;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: IgniteSnackbarData, public snackBarRef: MatSnackBarRef<IgniteSnackbarComponent>) {}

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.id)) {
      this.id = `ignite-snackbar-${IgniteSnackbarComponent.nextId++}`;
    }
    this.message = Utility.IsEmptyOrNull(this.data?.message) ? "" : this.data.message;
    this.action = Utility.IsEmptyOrNull(this.data?.action) ? "" : this.data.action;
    this.snackbarType = Utility.IsEmptyOrNull(this.data?.type) ? IgniteSnackbarType.Info : this.data.type;
  }

  onAction($event: Event): void {
    this.snackBarRef.dismissWithAction();
    $event.stopPropagation();
  }

  dismiss($event: Event): void {
    this.snackBarRef.dismiss();
    $event.stopPropagation();
  }
}
