import { Component, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnDestroy {
  public message: string;
  public title: string;
  public okButtonLabel: string;
  public cancelButtonLabel: string;
  dialogId: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnDestroy(): void {
    this.dialogRef = null;
  }

  public setup(message: string, title: string, okButtonLabel = "OK", cancelButtonLabel = "Cancel", id = "dialog_confirm"): void {
    this.message = message;
    this.title = title;
    this.okButtonLabel = okButtonLabel;
    this.cancelButtonLabel = cancelButtonLabel;
    this.dialogId = id;
  }
}
