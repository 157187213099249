import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IgniteBaseComponent } from "../../ignite-base/ignite-base.component";
import { Editor, Toolbar } from "ngx-editor";

@Component({
  selector: "notepad-dialog",
  templateUrl: "./notepad-dialog.component.html",
  styleUrls: ["./notepad-dialog.component.scss"],
})
export class NotepadDialogComponent extends IgniteBaseComponent implements OnInit, OnDestroy {
  @Output() changeEvent: EventEmitter<object> = new EventEmitter<object>();
  @Input() notes = "";
  @Input() title = "My Notepad";
  @Input() id = "notepad-id";
  state: "opened" | "closed" = "closed";
  editor: Editor;
  toolbar: Toolbar = [["bold", "italic"], ["underline", "strike"], ["text_color", "background_color"], ["bullet_list"]];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.editor = new Editor();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  toggle(): void {
    if (this.state === "closed") {
      this.state = "opened";
    } else {
      this.state = "closed";
    }
  }

  onChange(html: object) {
    this.changeEvent.emit(html);
  }

  onTabKey(e: KeyboardEvent) {
    e.stopPropagation();
    e.preventDefault();
    const sel = document.getSelection();
    const range = sel.getRangeAt(0);
    range.deleteContents();
    const span = document.createElement("span");
    span.innerHTML = "&emsp;";
    range.insertNode(span);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  }
}
