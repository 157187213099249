/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { Editor } from "ngx-editor";

@Component({
  selector: "notepad-custom-menu",
  templateUrl: "./custom-menu.component.html",
  styleUrls: ["./custom-menu.component.scss"],
})
export class NotepadCustomMenuComponent implements OnInit, AfterViewInit {
  @Input() editor: Editor;
  isActive = false;
  isDisabled = false;
  private $editor: HTMLElement;
  private os: string;

  ngOnInit(): void {
    const plugin = new Plugin({
      key: new PluginKey(`undo-redo`),
      view: () => {
        return {
          update: this.update,
        };
      },
    });

    this.editor.registerPlugin(plugin);
    this.os = this.getOS();
  }

  ngAfterViewInit(): void {
    this.$editor = document.querySelector(".NgxEditor__Content");
  }

  onUndoClick(e: MouseEvent): void {
    e.preventDefault();
    if (this.os === "macos" || this.os === "ios") {
      this.$editor.dispatchEvent(new KeyboardEvent("keydown", { key: "z", metaKey: true }));
    } else {
      this.$editor.dispatchEvent(new KeyboardEvent("keydown", { key: "z", ctrlKey: true }));
    }
  }

  onRedoClick(e: MouseEvent): void {
    e.preventDefault();
    if (this.os === "macos" || this.os === "ios") {
      this.$editor.dispatchEvent(new KeyboardEvent("keydown", { key: "y", metaKey: true }));
    } else {
      this.$editor.dispatchEvent(new KeyboardEvent("keydown", { key: "y", ctrlKey: true }));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  update = (view: EditorView) => {};

  private getOS(): string {
    const userAgent = navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i;

    let os = "";
    if (macosPlatforms.test(userAgent)) {
      os = "macos";
    } else if (iosPlatforms.test(userAgent)) {
      os = "ios";
    } else if (windowsPlatforms.test(userAgent)) {
      os = "windows";
    } else if (/android/.test(userAgent)) {
      os = "android";
    } else if (!os && /linux/.test(userAgent)) {
      os = "linux";
    }

    return os;
  }
}
