import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Utility } from "@ignite/ignite-common";
import { Observable, Subject } from "rxjs";
import { takeUntil, takeWhile } from "rxjs/operators";

export interface SaveDialogData {
  isSaved$: Observable<boolean>;
  savedMessage: string;
  hideOkButton?: boolean;
  savingMessage: string;
}

@Component({
  selector: "save-dialog",
  templateUrl: "./save-dialog.component.html",
  styleUrls: ["./save-dialog.component.scss"],
})
export class SaveDialogComponent implements OnInit, OnDestroy {
  savingMessage: string;
  isSaved: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(public dialogRef: MatDialogRef<SaveDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: SaveDialogData) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.savingMessage = Utility.IsEmptyOrNull(this.data.savingMessage) ? "Saving changes..." : this.data.savingMessage;

    if (!Utility.IsNull(this.data.isSaved$)) {
      this.data.isSaved$
        .pipe(
          takeUntil(this.unsubscribe$),
          takeWhile((saved) => saved === false, true)
        )
        .subscribe({
          next: (saved) => {
            this.isSaved = saved;
          },
          error: (error: HttpErrorResponse) => {
            this.dialogRef.close(false);
          },
        });
    }
  }
}
