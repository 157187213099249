/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Directive, ElementRef, AfterViewInit } from "@angular/core";
import { Utility } from "@ignite/ignite-common";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[ignite-progress-spinner]",
})
export class IgniteProgressSpinnerDirective implements AfterViewInit {
  observer: MutationObserver;

  constructor(private el: ElementRef) {
    this.observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === "attributes" && mutation.attributeName === "aria-valuenow") {
          const $target = mutation.target as HTMLElement;
          if ($target.classList.contains("mat-mdc-progress-spinner")) {
            const $percInd = $target.getElementsByClassName("percentage-indicator");
            $percInd[0].innerHTML = `${$target.getAttribute("aria-valuenow")}%`;
          }
        }
      });
    });
  }

  ngAfterViewInit(): void {
    const $el = this.el.nativeElement;
    $el.classList.add("ignite-progress-spinner");

    const $circle = $el.getElementsByTagName("circle");
    if (!Utility.IsEmptyOrNull($circle)) {
      const $outerCircle = $circle[0].cloneNode();
      $outerCircle.style.strokeDasharray = "0px";
      $outerCircle.style.strokeDashoffset = "0px";
      $outerCircle.style.stroke = "#e6e6e6";
      $circle[0].parentNode.insertBefore($outerCircle, $circle[0]);

      const percentageIndicator = document.createElement("div");
      percentageIndicator.classList.add("percentage-indicator");
      $el.appendChild(percentageIndicator);

      this.observer.observe($el, {
        attributes: true,
      });
    }
  }
}
