<div class="custom-menu-content">
  <div class="NgxEditor__Seperator"></div>
  <div
    class="NgxEditor__MenuItem NgxEditor__MenuItem--Text"
    (mousedown)="onUndoClick($event)"
    [ngClass]="{ 'NgxEditor__MenuItem--Active': isActive, 'NgxEditor--Disabled': isDisabled }"
  >
    <i class="fas fa-undo" title="Undo"></i>
  </div>
  <div
    class="NgxEditor__MenuItem NgxEditor__MenuItem--Text"
    (mousedown)="onRedoClick($event)"
    [ngClass]="{ 'NgxEditor__MenuItem--Active': isActive, 'NgxEditor--Disabled': isDisabled }"
  >
    <i class="fas fa-redo" title="Redo"></i>
  </div>
</div>
