<div class="dialog-notepad-container" [class.hidden]="state === 'closed'">
  <div [id]="id" class="dialog-notepad">
    <div class="dialog-title" cdkDrag cdkDragRootElement=".dialog-notepad-container" cdkDragBoundary="body" cdkDragHandle>
      <span>{{ title }}</span>
      <i class="fal fa-times-circle" (click)="toggle()"></i>
    </div>
    <div class="dialog-content">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
      <!-- Do not use customMenu for now
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" [customMenuRef]="customMenu"> </ngx-editor-menu>
      -->
      <ngx-editor
        [editor]="editor"
        [(ngModel)]="notes"
        (ngModelChange)="onChange($event)"
        (keydown.Tab)="onTabKey($event)"
        [placeholder]="'Type here...'"
      ></ngx-editor>
      <ng-template #customMenu>
        <notepad-custom-menu [editor]="editor"></notepad-custom-menu>
      </ng-template>
    </div>
  </div>
</div>
