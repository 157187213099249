<div class="ignite-panel-stepper">
  <div class="ignite-panel-stepper-header" [class.collapsed]="collapsed">
    <div class="ignite-panel-stepper-title" (click)="onToggle($event)">
      <div class="number-completed">
        <div *ngIf="completed">
          <i class="far fa-check"></i>
        </div>
        <div *ngIf="!completed">
          <span>{{ panelNumber }}</span>
        </div>
      </div>

      <div class="title-text">
        <span>
          {{ panelTitle }}
        </span>
      </div>

      <div *ngIf="collapsible" class="collapsible-indicator">
        <i class="fal fa-lg" [ngClass]="{ 'fa-chevron-down': collapsed, 'fa-chevron-up': !collapsed }"></i>
      </div>
    </div>
  </div>
  <div class="ignite-panel-stepper-content-wrapper" [class.isHidden]="collapsed">
    <ng-content></ng-content>
  </div>
</div>
