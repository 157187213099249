/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Utility } from "@ignite/ignite-common";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ignite-sidebar-button",
  templateUrl: "./ignite-sidebar-button.component.html",
  styleUrls: ["./ignite-sidebar-button.component.scss"],
})
export class IgniteSidebarButtonComponent implements OnInit {
  static nextId = 0;

  @Input() id: string;
  @Input() icon: string;
  @Input() badgeValue: string;
  @Input() badgeColor = "";
  @Output() clickEvent = new EventEmitter();

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.id)) {
      this.id = `ignite-sidebar-button-${IgniteSidebarButtonComponent.nextId++}`;
    }
  }

  badgeHidden() {
    return Utility.IsEmptyOrNull(this.badgeValue);
  }

  onClick($event): void {
    $event.stopPropagation();
    this.clickEvent.emit();
  }
}
