import { Component, Input, OnInit } from "@angular/core";
import { Utility } from "@ignite/ignite-common";

@Component({
  selector: "ignite-solo-card",
  templateUrl: "./ignite-solo-card.component.html",
  styleUrls: ["./ignite-solo-card.component.scss"],
})
export class IgniteSoloCardComponent implements OnInit {
  @Input() logoUrl: string;
  year = new Date().getUTCFullYear();

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.logoUrl)) {
      this.logoUrl = "/assets/images/pearsonpte-logo-b.svg";
    }
  }
}
