import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Utility } from "@ignite/ignite-common";

export function ParentEmailValidator(getTestTakerEmail: () => string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (Utility.IsEmptyOrNull(control.value) || control.invalid) {
      return null;
    }
    const ttEmail = getTestTakerEmail();
    if (Utility.IsEmptyOrNull(ttEmail)) {
      return null;
    }
    return (control.value as string)?.toLowerCase() === ttEmail.toLowerCase() ? { sameAsTestTakersEmail: true } : null;
  };
}
