<div class="ignite-panel">
  <div class="supplementary-text">
    <ng-content select="[supplementary-text]"></ng-content>
  </div>

  <div class="ignite-panel-header" [class.expanded]="!collapsed">
    <div class="ignite-panel-title" (click)="onToggle($event)">
      <div *ngIf="(showCancelButton || collapsible) && !collapsed" class="cancel-col">
        <i
          class="fal fa-times-circle panel-cancel"
          (click)="cancel($event)"
          matTooltip="Close"
          matTooltipClass="ignite-tool-tip"
          matTooltipPosition="above"
          matTooltipShowDelay="500"
        ></i>
      </div>
      <div *ngIf="collapsible && collapsed" class="toggle-col">
        <i class="fal fa-arrow-circle-right"></i>
      </div>
      <div
        id="ignite-panel-title-text"
        class="title-text"
        [class.link]="headerClickEvent.observers.length > 0"
        (click)="onHeaderClick($event)"
        *ngFor="let t of panelTitle"
      >
        <span [innerHTML]="t | safeHtml"></span>
      </div>
      <div *ngIf="collapsed && showCheckedIndicator" class="checked-indicator">
        <i class="fal fa-check-circle"></i>
      </div>
      <div id="ignite-panel-menu" *ngIf="panelMenuOptions && panelMenuOptions.length > 0">
        <ignite-panel-menu [menuOptions]="panelMenuOptions"></ignite-panel-menu>
      </div>
    </div>
  </div>
  <div [class.isHidden]="collapsed">
    <ng-content></ng-content>
  </div>
</div>
