import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HelpService {
  helpId: number | null;

  getHelpUrl(): string {
    const url = this.helpId === null ? "assets/help/pte_help.htm" : `assets/help/pte_help.htm#cshid=${this.helpId}`;
    this.helpId = null;
    return url;
  }
}
