export class IgniteProfileMenuOption {
  constructor(label: string, icon: string = null, route: string = null) {
    this.label = label;
    this.icon = icon;
    this.route = route;
  }

  label: string;
  icon: string;
  route: string;
}
