import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Utility } from "@ignite/ignite-common";
import { IgniteActionCardButton, IgniteActionType } from "../../models/ignite-action-card-button";

@Component({
  selector: "ignite-action-card",
  templateUrl: "./ignite-action-card.component.html",
  styleUrls: ["./ignite-action-card.component.scss"],
})
export class IgniteActionCardComponent implements OnInit {
  static nextId = 0;
  @Input() id: string;
  @Input() imageUrl: string;
  @Input() form: UntypedFormGroup;
  @Input() actions: IgniteActionCardButton[];
  @Output() actionEvent = new EventEmitter<Event>();

  IgniteActionType = IgniteActionType;

  ngOnInit(): void {
    if (Utility.IsEmptyOrNull(this.id)) {
      this.id = `ignite-action-card-${IgniteActionCardComponent.nextId++}`;
    }
  }

  actionPerformed($event: Event): void {
    this.actionEvent.emit($event);
  }
}
